class AppFontSizeContainer {
    private currentSize = 'default';

    public getCssClass() {
        if(this.currentSize === 'small') {
            return 'font-size-small';
        }
        if(this.currentSize === 'large') {
            return 'font-size-large';
        }
        return '';
    }

    public setSize(size: string) {
        this.currentSize = size;
    }

    public getSize() {
        return this.currentSize;
    }
}

const AppFontSize = new AppFontSizeContainer();
export default AppFontSize;