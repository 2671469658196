import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { Form } from "semantic-ui-react";
import { SimpleCrudPage } from "./SimpleCrudPage";
import { CreateEditModalFormProps } from "./CreateEditModal";

import GenericDataManagementService from "../../services/data-management/GenericDataManagementService";

interface ManagementOrganizationsProps {}

const CreateEditForm: FC<CreateEditModalFormProps> = (props) => {
  const { data, isCreate, updateField } = props;

  // Set initial user & password
  if (isCreate) {
    data.user = data.user || "myclimate.admin";
    data.password = data.password || "Initial2023!";
  }

  return (
    <div>
      <Form.Group widths="2">
        <Form.Input
          label="Name"
          value={data.name || ""}
          onChange={(event) => updateField("name", event.target.value)}
          readOnly={!isCreate}
        />
        <Form.Input
          label="Unternehmensgröße"
          type={"number"}
          value={data.companySize || ""}
          onChange={(event) => updateField("companySize", event.target.value)}
        />
        <Form.Select
          fluid
          label="Branche / Vorlage"
          value={data.sector || ""}
          onChange={(event, data) => updateField("sector", data.value)}
          options={[
            { key: "weinhandel", value: "Weinhandel", text: "Weinhandel" },
            {
              key: "detailhandel",
              value: "Detailhandel",
              text: "Detailhandel",
            },
            {
              key: "pharmahandel",
              value: "Pharmahandel",
              text: "Pharmahandel",
            },
          ]}
        ></Form.Select>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.TextArea
          label="Beschreibung"
          value={data.description || ""}
          onChange={(event) => updateField("description", event.target.value)}
        />
      </Form.Group>
      {!isCreate && (
        <Form.Group widths="equal">
          <Form.Input
            label="Umgebung - URL"
            value={data.url || ``}
            onChange={(event) => updateField("url", event.target.value)}
            readOnly={!isCreate}
          />
        </Form.Group>
      )}
      <Form.Group widths="equal">
        <Form.Input
          label="Admin User"
          value={data.user || ""}
          onChange={(event) => updateField("user", event.target.value)}
          readOnly={!isCreate}
        />
        <Form.Input
          label="Admin Initalpasswort"
          value={data.password || ""}
          onChange={(event) => updateField("password", event.target.value)}
          readOnly={!isCreate}
        />
      </Form.Group>
    </div>
  );
};

export const ManagementOrganizations: FC<ManagementOrganizationsProps> = (
  props
) => {
  const dataManagementService = new GenericDataManagementService();
  return (
    <SimpleCrudPage
      simulateLoading={true}
      dataManagementService={dataManagementService}
      entityType="organizations"
      singularName="Org"
      pluralName="Org Verwaltung"
      defaultSortField="name"
      defaultSortDirection="ASC"
      formComponent={CreateEditForm}
      modalHeader="Org"
      modalSize={"large"}
      tableColumns={[
        { title: "Name", field: "name", sortable: true },
        { title: "Branche / Vorlage", field: "sector", sortable: true },
        { title: "Unternehmensgröße", field: "companySize", sortable: true },
        { title: "Beschreibung", field: "description", sortable: true },
        { title: "Umgebung - URL", field: "url", sortable: true },
        { title: "Admin User", field: "user", sortable: true },
      ]}
    />
  );
};
