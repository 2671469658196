import React, {FC, useEffect, useState} from "react";
import { Form } from "semantic-ui-react";
import { SimpleCrudPage } from "./SimpleCrudPage";
import { CreateEditModalFormProps } from "./CreateEditModal";
import GenericDataManagementService from "../../services/data-management/GenericDataManagementService";
import Source from "../../types/source";
import UserRole from "../../types/user-role";

interface ManagementUsersProps {}

const CreateEditForm: FC<CreateEditModalFormProps> = (props) => {
    const { data, isCreate, updateField } = props;

    const dataService = new GenericDataManagementService();

    const [roles, setRoles] = useState<UserRole[]>([]);

    useEffect(() => {
        dataService.listDocuments("role", 0, "name", "ASC").then((roles) => {
            setRoles(roles.data.content as UserRole[]);
        });
    }, [setRoles]);

    return (
        <div style={{minHeight: "350px"}}>
            <Form.Group widths="3">
                <Form.Input
                    label="UserId"
                    value={data.userId || ""}
                    onChange={(event) => updateField("userId", event.target.value)}
                    readOnly={!isCreate}
                />
                <Form.Input
                    label="Name"
                    value={data.name || ""}
                    onChange={(event) => updateField("name", event.target.value)}
                />
                <Form.Input
                    label="Mail"
                    value={data.mail || ""}
                    onChange={(event) => updateField("mail", event.target.value)}
                />
            </Form.Group>
            <Form.Group widths="2">
                <Form.Select
                    label="Sprache"
                    value={data.language || ""}
                    onChange={(event, data) => updateField("language", data.value)}
                    options={[
                        { key: "DE", value: "DE", text: "Deutsch" },
                        { key: "EN", value: "EN", text: "Englisch" },
                    ]}
                ></Form.Select>
                <Form.Input
                    type={"password"}
                    label="Password"
                    value={data.password || ""}
                    onChange={(event) => updateField("password", event.target.value)}
                />

            </Form.Group>
            <Form.Group widths="2">
                <Form.Select
                    label="Typ"
                    value={data.type || ""}
                    onChange={(event, data) => updateField("type", data.value)}
                    options={[
                        { key: "user", value: "user", text: "Benutzer" },
                        { key: "admin", value: "admin", text: "Administrator" },
                    ]}
                ></Form.Select>
                <Form.Select
                    label="Rollen"

                    disabled={data.type !== "user"}

                    value={data.roles || []}
                    multiple={true}
                    onChange={(event, data) => updateField("roles", data.value)}
                    options={roles.map((role) => {
                        return { key: role.name, value: role.name, text: role.name };
                    })}
                />
            </Form.Group>
        </div>
    );
};

export const ManagementUsers: FC<ManagementUsersProps> = (props) => {
    const dataManagementService = new GenericDataManagementService();
    return (
        <SimpleCrudPage
            dataManagementService={dataManagementService}
            entityType="user"
            singularName="Benutzer"
            pluralName="Benutzer"
            defaultSortField="userId"
            defaultSortDirection="ASC"
            formComponent={CreateEditForm}
            tableColumns={[
                { title: "Id", field: "userId", sortable: true },
                { title: "Name", field: "name", sortable: true },
                { title: "E-Mail", field: "mail", sortable: true }
            ]}
        />
    );
};
