import * as React from "react";
import { useMemo, useState } from "react";
import {
  Divider,
  Grid,
  Icon,
  Progress,
  Segment,
  Table,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import {
  KpiChart,
  LineChartComponent,
} from "../components/Charts/ChartComponents";
import { endOfDay, format, parse, startOfDay, subDays } from "date-fns";
import { useKpi } from "../components/Charts/KpiApi";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";

const DashboardCompany = (props: RouteComponentProps) => {
  const [filterDateFrom, setFilterDateFrom] = useState(
    startOfDay(Date.parse("2021-01-02"))
  );
  const [filterDateTo, setFilterDateTo] = useState(endOfDay(Date.parse("2023-12-31")));

  const dailyCompensationAndEmissions = useKpi({
    kpiType: "co2",
    grouping: "type",
    dateFrom: filterDateFrom,
    dateTo: filterDateTo,
    stepSize: "YEARLY"
  });

  const dailyEmissionsByScope = useKpi({
    kpiType: "co2",
    grouping: "fields.scope",
    dateFrom: filterDateFrom,
    dateTo: filterDateTo,
    filters: [{ fieldName: "type", operator: "equal", value: "co2" }],
    stepSize: "YEARLY"
  });

  const totalEmissionsPerScope = useKpi({
    kpiType: "co2",
    grouping: "fields.scope",
    dateFrom: filterDateFrom,
    dateTo: filterDateTo,
    stepSize: "TOTAL",
    filters: [{ fieldName: "type", operator: "equal", value: "co2" }],
  });

  const totalEmissionsPerSource = useKpi({
    kpiType: "co2",
    grouping: "source",
    dateFrom: filterDateFrom,
    dateTo: filterDateTo,
    stepSize: "TOTAL",
    filters: [{ fieldName: "type", operator: "equal", value: "co2" }],
  });

  const totalsPerKpiType = useKpi({
    kpiType: "co2",
    grouping: "type",
    dateFrom: filterDateFrom,
    dateTo: filterDateTo,
    stepSize: "TOTAL",
  });

  const emissionsBySourceSortedTop10 = useMemo(() => {
    const data = totalEmissionsPerSource.data?.[0] || {};
    const sources = Object.keys(data);
    const sourceWithEmissions = sources.map((source) => ({
      source,
      emissions: data[source],
    }));

    return sourceWithEmissions
      .sort((a, b) => b.emissions - a.emissions)
      .slice(0, 10);
  }, [totalEmissionsPerSource.data]);

  return (
    <>
      <h1>Dashboard - CCF Jahresberechnung 2022</h1>
      <br />
      <Segment>
        <h3>Filter</h3>
        <Grid>
          <Grid.Row columns={5}>
            <Grid.Column>
              Von:
              <DateInput
                name="datesRangeFrom"
                placeholder="Von"
                disabled={true}
                value={format(filterDateFrom, "yyyy-MM-dd")}
                iconPosition="left"
                dateFormat="YYYY-MM-DD"
                onChange={(event: any, { value }: any) => {
                  setFilterDateFrom(
                    startOfDay(parse(value, "yyyy-MM-dd", new Date()))
                  );
                }}
              ></DateInput>
            </Grid.Column>
            <Grid.Column>
              Bis:
              <DateInput
                name="datesRangeTo"
                placeholder="Von"
                disabled={true}
                value={format(filterDateTo, "yyyy-MM-dd")}
                iconPosition="left"
                dateFormat="YYYY-MM-DD"
                onChange={(event: any, { value }: any) => {
                  setFilterDateTo(
                    endOfDay(parse(value, "yyyy-MM-dd", new Date()))
                  );
                }}
              ></DateInput>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Grid>
        <Grid.Row columns={5}>
          <Grid.Column>
            <Segment inverted color={"yellow"}>
              <h2>
                <Icon name={"industry"} />
                Emissionen
              </h2>
              <Divider></Divider>
              <h2>{(totalsPerKpiType.data[0]?.["co2"] / 1000).toFixed(3).replace(".", ",") || "0"} t</h2>
            </Segment>
          </Grid.Column>
          <Grid.Column>
          <a onClick={()=> props.history.push("/scope1")} style={{cursor: "pointer", textDecorationColor: "white"}}>
            <Segment inverted color={"yellow"}>
              <h2>
                <Icon name={"industry"} />
                Scope 1
              </h2>
              <Divider></Divider>
              <h2>{(totalEmissionsPerScope.data[0]?.["1"] / 1000).toFixed(3).replace(".", ",")|| "0"} t</h2>
            </Segment>

          </a>
          </Grid.Column>
          <Grid.Column>
            <a onClick={()=> props.history.push("/scope2")} style={{cursor: "pointer", textDecorationColor: "white"}}>
            <Segment inverted color={"yellow"}>
              <h2>
                <Icon name={"industry"} />
                Scope 2
              </h2>
              <Divider></Divider>
              <h2>{(totalEmissionsPerScope.data[0]?.["2"] / 1000).toFixed(3).replace(".", ",") || "0"} t</h2>
            </Segment>
            </a>
          </Grid.Column>
          <Grid.Column>
            <a onClick={()=> props.history.push("/scope3")} style={{cursor: "pointer", textDecorationColor: "white"}}>
            <Segment inverted color={"yellow"}>
              <h2>
                <Icon name={"industry"} />
                Scope 3
              </h2>
              <Divider></Divider>
              <h2>{(totalEmissionsPerScope.data[0]?.["3"] / 1000).toFixed(3).replace(".", ",") || "0"} t</h2>
            </Segment>
            </a>
          </Grid.Column>
          <Grid.Column>
            <Segment inverted color={"green"}>
              <h2>
                <Icon name={"globe"} />
                Kompensiert
              </h2>
              <Divider></Divider>
              <h2>{totalsPerKpiType.data[0]?.["compensation"] / 1000 || "0"} t</h2>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Column width={8}>
          <Segment>
            <h2>CO2-Emissionen und Kompensationen (in kg)</h2>
            <LineChartComponent
              data={dailyCompensationAndEmissions.data}
              lines={[
                { dataKey: "co2", name: "Emissionen" },
                { dataKey: "compensation", name: "Kompensation erfüllt (t)" },
              ]}
            />
          </Segment>
        </Grid.Column>
        <Grid.Column width={8}>
          <Segment>
            <h2>CO2-Emissionen nach Scope (in kg)</h2>
            <LineChartComponent data={dailyEmissionsByScope.data} />
          </Segment>
        </Grid.Column>

        {/* Examples with KpiChart */}
        <Grid.Column width={8}>
          <Segment>
            <h2>CO2-Emissionen nach Scope (in kg)</h2>
            <KpiChart
              kpiType="co2"
              chartType="pie"
              grouping="fields.scope"
              stepSize="TOTAL"
              dateFrom={filterDateFrom}
              dateTo={filterDateTo}
              filters={[{ fieldName: "type", operator: "equal", value: "co2" }]}
            />
          </Segment>
        </Grid.Column>
        <Grid.Column width={8}>
          <Segment>
            <h2>CO2-Emissionen nach Quelle (in kg)</h2>
            <KpiChart
              kpiType="co2"
              chartType="pie"
              grouping="source"
              stepSize="TOTAL"
              dateFrom={filterDateFrom}
              dateTo={filterDateTo}
              filters={[{ fieldName: "type", operator: "equal", value: "co2" }]}
            />
          </Segment>
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Column width={8}>
          {([] as any[]).map((project) => {
            return (
              <Segment key={project.projectId}>
                <h3>
                  Kompensationsprojekt {project.projectName} #
                  {project.projectId}
                </h3>
                <p>{project.projectState}</p>

                {project.amounts.map((amount: any) => {
                  return (
                    <Progress
                      key={amount.species}
                      total={amount.planned}
                      value={amount.planted}
                      label={amount.species}
                      success
                      content={
                        amount.species +
                        " - " +
                        amount.planted +
                        " / " +
                        amount.planned
                      }
                    />
                  );
                })}
              </Segment>
            );
          })}
        </Grid.Column>
        <Grid.Column width={8}>
          <Segment>
            <h3>Emissionsquellen</h3>

            <Table>
              <Table.Body>
                {emissionsBySourceSortedTop10.map((source) => {
                  return (
                    <Table.Row key={source.source}>
                      <Table.Cell>{source.source}</Table.Cell>
                      <Table.Cell>{source.emissions.toFixed(0)} kg</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Segment>
          <Segment>
            <h3>Nachhaltigkeitsziele 2023</h3>

            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Kategorie</Table.HeaderCell>
                  <Table.HeaderCell>Emissionen Q1 '22</Table.HeaderCell>
                  <Table.HeaderCell>Emissionen Q1 '23</Table.HeaderCell>
                  <Table.HeaderCell>Veränderung</Table.HeaderCell>
                  <Table.HeaderCell>Ziel</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {([] as any[]).map((goal) => {
                  return (
                    <Table.Row key={goal.goalYear + "_" + goal.goalCategory}>
                      <Table.Cell>{goal.goalCategory}</Table.Cell>
                      <Table.Cell>{goal.emissionsQ122} t</Table.Cell>
                      <Table.Cell>{goal.emissionsQ123} t</Table.Cell>
                      <Table.Cell>{goal.emissionChange}%</Table.Cell>
                      <Table.Cell>{goal.emissionChangePlanned}%</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default withRouter(DashboardCompany);
