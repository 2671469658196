import React from 'react';
import {
  useLocation,
  useHistory,
  useParams,
} from "react-router-dom";

// helper wrapper for old class components not ready to use new react-router hooks
export function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let history = useHistory();
    let params = useParams();
    return (
      <Component
        {...props}
        location={location}
        history={history}
        params={params}
      />
    );
  }

  return ComponentWithRouterProp;
}