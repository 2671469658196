import * as React from 'react';
import './LoadingContainer.css';


import loadingImg from "../../loading.svg";


class LoadingContainer extends React.Component<{}, {}> {
    render() {


        return (
            <>
                <div className={"loading-spinner"} />
                <img src={loadingImg}/>
            </>
        );
    }
}

export default LoadingContainer;
