class ExportContextHolder {

    static enabled: boolean = false;

    static loadFunctionV2: ((page:number, size:number) => Promise<{page: number; totalPages: number; rows: any[]}>) |null = null;

    static rowsToRender: any;

    static fields: string[];

    static reloadHeaderFunc: Function;

    static routeData: string[] | null;

    static reset() {
        ExportContextHolder.enabled = false;
        ExportContextHolder.fields = [];
        ExportContextHolder.loadFunctionV2 = null;
        ExportContextHolder.routeData = null;
        ExportContextHolder.reloadHeaderFunc();

        ExportContextHolder.rowsToRender = null;
    }


    static exportPrintV2(finish: (data: any[]) => void, progress: (current: number, total: number) => void, error: (error: any) => void) {
        if (!ExportContextHolder.enabled || ExportContextHolder.loadFunctionV2 === null) {
            error(new Error("Print Export is not set up"));
            return;
        }
        const BATCH_SIZE = 5000;

        const rows: any[] = [];
        rows.push(ExportContextHolder.fields);

        const fetchBatch = async (page: number) => {
            if(ExportContextHolder.loadFunctionV2 === null) {
                error(new Error("Print Export is not set up"));
                return;
            }
            const result = await ExportContextHolder.loadFunctionV2(page, BATCH_SIZE);
            progress(page+1, result.totalPages);
            rows.push(...result.rows);
            if(page + 1 >= result.totalPages) {
                finish(rows);
            } else {
                setTimeout(async () => await fetchBatch(page+1) , 0);
            }
        };
        fetchBatch(0).then(() => {
            // do nothing, finishing is handled in fetchBatch itself
        })
    }
}

export default ExportContextHolder;