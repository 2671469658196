import React, {FC, useCallback, useEffect, useState} from "react";
import { SimpleCrudPage } from "./SimpleCrudPage";
import InputTypeDataManagementService from "../../services/data-management/InputTypeDataManagementService";
import {CreateEditModalFormProps} from "./CreateEditModal";
import {Accordion, Form, Icon, Label} from "semantic-ui-react";
import JsonSchemaEditor from "@optum/json-schema-editor";
import {JSONSchema7} from "@optum/json-schema-editor/dist/JsonSchemaEditor.types";
import {CodeEditor} from "../../components/CodeEditor/CodeEditor";

interface ManagementInputTypeProps {}

const CreateEditForm: FC<CreateEditModalFormProps> = (props) => {
    const { data, isCreate, updateField } = props;

    const [schemaOpen, setSchemaOpen] = useState<boolean>(false);
    const [calculationOpen, setCalculationOpen] = useState<boolean>(false);

    const updateSchema = useCallback((schema: any) => {
        setSchema(schema);
    }, [props]);

    const [schema, setSchema] = useState<JSONSchema7 | undefined>(undefined);

    useEffect(() => {
        props.data.fieldsSchema && !schema && setSchema(JSON.parse(props.data.fieldsSchema));
    }, [props, props.data, data.fieldsSchema]);

    useEffect(() => {
        updateField("fieldsSchema", schema)
    }, [schema]);

    return (
        <div>
            <Form.Group widths="2">
                <Form.Input
                    label="Name"
                    value={data.name || ""}
                    onChange={(event) => updateField("name", event.target.value)}
                    readOnly={!isCreate}
                />
                <Form.Input
                    label="Version"
                    type={"number"}
                    value={data.version || ""}
                    onChange={(event) => updateField("version", event.target.value)}
                />
                <Form.TextArea
                    label="Beschreibung"
                    value={data.description || ""}
                    onChange={(event) => updateField("description", event.target.value)}
                />
            </Form.Group>

            {/*TODO: toggle button for switch between JsonSchemaEditor and CodeEditor*/}
            <Form.Group widths={16}>
                <Accordion fluid styled>
                    <Form.Field width={16}>
                        <Accordion.Title active={schemaOpen}
                                         index={0}
                                         onClick={() => setSchemaOpen(!schemaOpen)}>
                            <Icon name='dropdown' />
                            <label>Schema</label>
                        </Accordion.Title>
                        <Accordion.Content active={schemaOpen}>
                            {
                                (props.data.fieldsSchema && schema) || isCreate ? <JsonSchemaEditor data={schema} onSchemaChange={updateSchema} /> : <></>
                            }
                        </Accordion.Content>
                    </Form.Field>
                    <Form.Field width={16}>
                        <Accordion.Title active={calculationOpen}
                                         index={0}
                                         onClick={() => setCalculationOpen(!calculationOpen)}>
                            <Icon name='dropdown' />
                            <label>Transaktionsberechnungen</label>
                        </Accordion.Title>
                        <Accordion.Content active={calculationOpen}>
                            <label>("Transaktionstyp": "Berechnungslogik")</label>
                            <CodeEditor value={JSON.stringify(data.transactionCalculations)} updateValue={(data) => updateField("transactionCalculations", data)} language="json" deps={[data.name, data.version, data.description, data.fieldsSchema]} />
                        </Accordion.Content>
                    </Form.Field>
                </Accordion>
            </Form.Group>
                    {/*<JsonSchemaEditor data={data.fieldsSchema} readOnly={false} onSchemaChange={(data) => updateField("fieldsSchema", data)} deps={[data, data.name, data.version, data.description, data.transactionCalculations, data.fieldsSchema]}/>*/}
                    {/**
                        data.fieldsSchema != undefined && !isCreate ?
                            <JsonSchemaEditor data={data.fieldsSchema} readOnly={false} onSchemaChange={(data) => updateField("fieldsSchema", data)} deps={[data.name, data.version, data.description, data.transactionCalculations, data.fieldsSchema]}/>
                            : isCreate ? <JsonSchemaEditor data={"{}"} readOnly={false} onSchemaChange={(data) => updateField("fieldsSchema", data)} deps={[data.name, data.version, data.description, data.transactionCalculations, data.fieldsSchema]}/>
                            : <></>
                    **/}



            {/*<Form.Group>*/}
            {/*    /!*<Form.Field width={8}>*!/*/}
            {/*    /!*    <label>Schema</label>*!/*/}
            {/*    /!*    <CodeEditor value={data.fieldsSchema} updateValue={(data) => updateField("fieldsSchema", data)} language="json" deps={[data.name, data.version, data.description, data.transactionCalculations, data.fieldsSchema]} />*!/*/}
            {/*    /!*</Form.Field>*!/*/}
            {/*    <Form.Field width={16}>*/}
            {/*        <label>Transaktionsberechnungen ("Transaktionstyp": "Berechnungslogik")</label>*/}
            {/*        <CodeEditor value={JSON.stringify(data.transactionCalculations)} updateValue={(data) => updateField("transactionCalculations", data)} language="json" deps={[data.name, data.version, data.description, data.fieldsSchema]} />*/}
            {/*    </Form.Field>*/}
            {/*</Form.Group>*/}
        </div>
    );
};

export const ManagementInputType: FC<ManagementInputTypeProps> = (props) => {
  const dataManagementService = new InputTypeDataManagementService();
  return (
    <SimpleCrudPage
      entityType="input-type"
      dataManagementService={dataManagementService}
      singularName="Input-Type"
      pluralName="Input-Types"
      defaultSortField="name"
      defaultSortDirection="ASC"
      modalSize={"fullscreen"}
      formComponent={CreateEditForm}
      tableColumns={[
        { title: "Name", field: "name", sortable: true },
        { title: "Beschreibung", field: "description", sortable: true },
        { title: "Version", field: "version", sortable: true },
      ]}
    />
  );
};
