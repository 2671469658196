import React, {useEffect, useRef} from "react";
import {EditorState} from "@codemirror/state";
import {
    crosshairCursor,
    drawSelection, dropCursor,
    EditorView, highlightActiveLine,
    highlightActiveLineGutter,
    highlightSpecialChars, keymap,
    lineNumbers, rectangularSelection,
    ViewPlugin,
    ViewUpdate
} from "@codemirror/view";
import {defaultKeymap, history, historyKeymap} from "@codemirror/commands";
import {
    bracketMatching,
    defaultHighlightStyle,
    foldGutter, foldKeymap,
    indentOnInput,
    syntaxHighlighting
} from "@codemirror/language";
import {autocompletion, closeBrackets, closeBracketsKeymap, completionKeymap} from "@codemirror/autocomplete";
import {highlightSelectionMatches, searchKeymap} from "@codemirror/search";
import {lintKeymap} from "@codemirror/lint";
import {java} from "@codemirror/lang-java";
import {Grid, Responsive} from "semantic-ui-react";
import LoadingSpinner from "../../containers/LoadingContainer/LoadingSpinner";
import {json} from "@codemirror/lang-json";


interface CodeEditorProps {
    value: string;
    updateValue: (data: string) => void;
    deps: any[];

    language: string;
}

export const CodeEditor = (props: CodeEditorProps) => {

    const editor = useRef(null);
    const updateValue = props.updateValue;


    useEffect(() => {

        if (editor === null) {
            return;
        }

        let parent: React.MutableRefObject<any> = editor;

        const conditionalExtensions = [];

        if (props.language === "java") {
            conditionalExtensions.push(java());
        }
        if (props.language === "groovy") {
            conditionalExtensions.push(java());
        }
        if (props.language === "json") {
            conditionalExtensions.push(json());
        }


        const state = EditorState.create({
            doc: props.value || "",
            extensions: [
                ViewPlugin.fromClass(
                    class {
                        constructor(view: EditorView) {
                        }

                        update(update: ViewUpdate) {
                            if (update.docChanged)
                                props.updateValue(update.state.doc.toString().replace("\n", ""));
                        }
                    }
                ),

                lineNumbers(),
                highlightActiveLineGutter(),
                highlightSpecialChars(),
                history(),
                foldGutter(),
                drawSelection(),
                dropCursor(),
                EditorState.allowMultipleSelections.of(true),
                indentOnInput(),
                syntaxHighlighting(defaultHighlightStyle, {fallback: true}),
                bracketMatching(),
                closeBrackets(),
                autocompletion(),
                rectangularSelection(),
                crosshairCursor(),
                highlightActiveLine(),
                highlightSelectionMatches(),
                keymap.of([
                    ...closeBracketsKeymap,
                    ...defaultKeymap,
                    ...searchKeymap,
                    ...historyKeymap,
                    ...foldKeymap,
                    ...completionKeymap,
                    ...lintKeymap,
                ]),
                ...conditionalExtensions,
            ],
        });
        const view = new EditorView({state, parent: parent.current});

        return () => {
            view.destroy();
        };



    }, [editor, ...props.deps]);

    return (<div style={{width: "100%"}} ref={editor}/>);
};