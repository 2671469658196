import {useEffect, useState} from "react";
import RestClient from "../../../../lib/RestClient";

type FilterValueType = string | number | boolean;
type StepSize = 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY' | 'TOTAL';
type AggregationType = 'SUM' | 'AVG' | 'MIN' | 'MAX';

/**
 * For use with the KPI API filters parameter.
 */
interface KpiFilter {
    fieldName: string;
    operator: 'equal' | 'unequal' | 'range' | 'and' | 'or' | 'in';
    value?: FilterValueType;
    values?: (FilterValueType)[];
    from?: FilterValueType;
    to?: FilterValueType;
    filters?: KpiFilter[];
}

/**
 * Parameters for the KPI API hook.
 */
export interface KpiApiParameters {

    kpiType: string;
    /**
     * The field to group by, e.g. 'source', 'type' or 'fields.scope',
     */
    grouping: string;

    /**
     * The start date of the data to fetch.
     */
    dateFrom: Date;

    /**
     * The end date of the data to fetch.
     */
    dateTo: Date;

    /**
     * Step Size - default is 'DAILY'
     */
    stepSize?: StepSize;

    /**
     * Aggregation type - default is 'SUM'
     */
    aggregationType?: AggregationType;

    /**
     * Filters to apply to the data.
     */
    filters?: KpiFilter[];
}

/**
 * Fetches data from the KPI endpoint and returns it in a format usable
 *  by the chart components or for simple data display.
 *
 * @param filterParams
 */
export function useKpi(filterParams: KpiApiParameters) {
    // State
    const [data, setData] = useState<Record<string, any>[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    // API params & defaults
    const dateFrom = filterParams.dateFrom.toISOString();
    const dateTo = filterParams.dateTo.toISOString();
    const stepSize = filterParams.stepSize || 'DAILY';
    const aggregationType = filterParams.aggregationType || 'SUM';
    const grouping = filterParams.grouping;

    // Fetch data
    const fetchKpi = async () => {
        setLoading(true);
        const response = await RestClient.get("/kpi-aggregation/" + filterParams.kpiType, {
            dateFrom,
            dateTo,
            stepSize,
            aggregationType,
            grouping,
            filter: JSON.stringify(filterParams.filters || []),
        });
        const responseData = await response.data;

        const data = transformKpiApiResponse(responseData, stepSize);

        setData(data);
        setLoading(false);
    }

    useEffect(() => {
        fetchKpi().then();
    }, [dateFrom, dateTo]);

    return { data, loading, refresh: fetchKpi };
}

/**
 * Transforms the response data from the kpi endpoint to a format
 * usable by the chart components.
 *
 * The response structure is as follows:
 *   {
 *       "dataset1": [1,2,3],
 *       "dataset2": [1,2,3],
 *       "dataset3": [1,2,3],
 *       "date": ["2023-01-01", "2023-01-02", "2023-01-03"],
 *   }
 *
 * This function transforms the data by merging all arrays at the same index to
 *   an array of objects. The example above would be transformed to:
 *
 *   [
 *    { dataset1: 1, dataset2: 1, dataset3: 1, date: "2023-01-01" },
 *    { dataset1: 2, dataset2: 2, dataset3: 2, date: "2023-01-02" },
 *    { dataset1: 3, dataset2: 3, dataset3: 3, date: "2023-01-03" },
 *   ]
 *
 *  If the stepSize is set to 'TOTAL', the API returns the data without arrays.
 *  In that case, the data is wrapped in an array to make it consistent.
 *
 * @param responseData
 * @param stepSize
 */
function transformKpiApiResponse(responseData: Record<string, any>, stepSize: StepSize) {
    if (!Object.keys(responseData).length) {
        return [];
    }
    if (stepSize === 'TOTAL') {
        return [ responseData ];
    }
    return responseData[Object.keys(responseData)[0]].map((_: any, i: number) => {
        const obj: Record<string, any> = {};
        Object.keys(responseData).forEach(key => {
            obj[key] = responseData[key][i];
        });
        return obj;
    });
}