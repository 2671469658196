import Cookies from 'universal-cookie';
import RestClient from '../../../lib/RestClient';
import i18n from "../../../locale/i18n";
import {appInfo} from './AppInfo';
import UserRole from "../types/user-role";
import GenericDataManagementService from "./data-management/GenericDataManagementService";

interface AuthCredentials {
    userId: string;
    password: string;
}

export interface AuthPrincipal {
    userId: string;
    name: string;
    privileges: string[];
    language: string;
    mail: string;
    type: string;
    roles: string[];
}

const LOGIN_URL = "/auth";
const LOGOUT_URL = '/logout';
const ME_URL = '/auth/me';

const OPENID_LOGIN_REDIRECT_URL = '/openid-connect/login';

class AuthService {
    private cookieStore: Cookies;
    private _loggedIn: boolean = false;
    private _principal: AuthPrincipal;

    private expire = 0;

    private _roles: UserRole[] = [];

    constructor () {
        this.cookieStore = new Cookies();

        this._principal = {
            userId: '',
            name: '',
            privileges: [],
            language: '',
            mail: '',
            type: '',
            roles: []
        };
    }

    get principal(): AuthPrincipal {
        return this._principal;
    }

    set principal(value: AuthPrincipal) {
        this._principal = value;
    }

    get loggedIn(): boolean {
        //return true;
        return this._loggedIn;
    }

    set loggedIn(value: boolean) {
        this._loggedIn = value;
    }

    get roles(): UserRole[] {
        return this._roles;
    }

    set roles(roles: UserRole[]) {
        this._roles = roles;
    }

    retrieveGlobalSettings() {
        return new Promise((resolve, reject) => {
            RestClient.get('user-settings').then((data) => {
                resolve({
                    language: (data.data && data.data.global && data.data.global.language) ? data.data.global.language : i18n.language,
                    entryPoint: (data.data && data.data.global && data.data.global.entryPoint) ? data.data.global.entryPoint : 'auctions',
                    fontSize:  (data.data && data.data.global && data.data.global.fontSize) ? data.data.global.fontSize : 'default',
                });
            }).catch(err => reject(err));
        });
    }

    retrieveRoles() {
        return new Promise((resolve, reject) => {

            const dataService = new GenericDataManagementService();

            dataService.listDocuments("roles", 0, "name", "ASC").then((data) => {
                this.roles = data.data as UserRole[];
                resolve(data);
            }).catch(err => reject(err));
        });
    }

    dummyRequest() {
        return new Promise((resolve, reject) => {

            this.checkIfLoggedIn().then(
                () => {
                    resolve();
                }
            ).catch(
              () => {
                  resolve();
              }
            );
        });
    }

    checkIfLoggedIn() {
        return new Promise((resolve, reject) => {

            RestClient.get(ME_URL).then((response) => {
                this.loggedIn = true;
                this._principal = response.data;

                resolve();
            }).catch((error) => {
                this.loggedIn = false;
                reject();
            });
        });
    }

    redirectToLoginForm() {
        if(appInfo.authenticationMethod === 'openid_connect') {
            window.location.href = OPENID_LOGIN_REDIRECT_URL;
        } else {
            window.location.href = '#/login';
        }
    }

    login(credentials: AuthCredentials) {
        let formData = {userId: credentials.userId, password: credentials.password};
        return new Promise((resolve, reject) => {
            RestClient.post(LOGIN_URL, formData)
                .then((response) => {
                    this.checkIfLoggedIn().then(resolve, reject);
                })
                .catch((error) => {
                    this.loggedIn = false;
                    let message = 'Error.UnknownError';
                    if (error.response) {
                        if (error.response.status === 401) {
                            message = 'Error.WrongCredentials';
                        } else if (error.response.status === 403) {
                            message = 'Error.XSRFError';
                        } else if (error.response.status === 500) {
                            message = 'Error.InternalServerError';
                        }
                    } else if (error.request) {
                        message = 'Error.ServerCouldNotBeReached';
                    } else {
                        message = 'Error.CouldNotSendRequest';
                    }
                    reject(message);
                });
        });
    }

    logout() {
        // TODO getAuthToken is no longer a valid check for whether to use oidc or native logout
        //          use appInfo authenticationMethod instead
        return new Promise((resolve, reject) => {
            if(appInfo.authenticationMethod !== 'openid_connect') {
                // classic form based auth - no bearer token
                RestClient.post(LOGOUT_URL).then(() => {
                    this.loggedIn = false;
                    resolve();
                }).catch((err) => reject(err));
            } else {
                // auth via DPGW / keyCloak
                RestClient.post(LOGOUT_URL + '/oic').then((response) => {
                    const logoutUrl = response.data.logoutUrl;
                    window.location.href = logoutUrl + '?redirect_uri=' + encodeURIComponent(window.location.origin);
                    // no resolve of promise needed, as user will leave page and application unloads
                }).catch((err) => reject(err));
            }
        });
    }
}

const authService = new AuthService();

export default authService;
