import * as React from "react";
import './ContentContainer.css';
import authService from "../../services/AuthService";
import AppRoute from "../../AppRoute";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router";
import routes from "../../routes";
import _throttle from 'lodash/throttle';

import {Sidebar} from 'semantic-ui-react';

import MenuSidebar from "../../components/MenuSidebar/MenuSidebar";
import AppHeader from "../../components/AppHeader/AppHeader";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import AppFontSize from "../../../../fontSize";
import {Ref, RefObject} from "react";

interface IContentContainerState {
    routes: AppRoute[];

    showScrollToTop: boolean;
    bottomOffset: number;
}

class ContentContainer extends React.Component<RouteComponentProps<{}>, IContentContainerState> {
    private defaultRoute: string;
    private sidebarRef: any = React.createRef();
    public componentRef: RefObject<HTMLDivElement> = React.createRef();
    public bottomHiddenProbeRef: RefObject<HTMLDivElement> = React.createRef();
    static instance: ContentContainer;

    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {
            routes: routes,
            showScrollToTop: false,
            bottomOffset: 0
        };

        this.defaultRoute = localStorage.getItem('DEBUG_DEFAULT_ROUTE') || '/';
        ContentContainer.instance = this;

        this.scrollToTop = this.scrollToTop.bind(this);
    }

    public componentDidMount() {
        // Add scroll event listener to hide/show "scroll to top" button
        if(this.componentRef.current) {
            this.componentRef.current.addEventListener('scroll', _throttle((ev) => {
                if(this.componentRef.current) {
                    const showScrollTop = this.componentRef.current.scrollTop > 100;
                    if(showScrollTop !== this.state.showScrollToTop) {
                        this.setState({showScrollToTop: showScrollTop});
                    }
                }
                // check if invisible div positioned at bottom:0 is inside of window
                // if not, set offset for scroll to top button.
                // this prevents safaris bottom bar to hide the button
                if(this.bottomHiddenProbeRef.current) {
                    const boundingRect = this.bottomHiddenProbeRef.current.getBoundingClientRect();
                    let newOffset = 0;
                    if(typeof boundingRect.bottom !== 'undefined' && Math.floor(boundingRect.bottom) > window.innerHeight) {
                        newOffset = Math.floor(boundingRect.bottom) - window.innerHeight + 10;
                    }
                    if(newOffset !== this.state.bottomOffset) {
                        this.setState({bottomOffset: newOffset});
                    }
                }
            }, 100));
        }
    }

    public scrollToTop() {
        if('scrollBehavior' in document.documentElement.style) {
            this.componentRef.current?.scrollTo({
               top: 0,
               left: 0,
               behavior: 'smooth'
            });
        } else {
            this.componentRef.current?.scrollTo(0, 0);
        }
    }

    public componentWillUnmount() {
    }

    public logout(): void {
        authService.logout().then(() => window.location.href = "/");
    }

    public render() {
        const routeComponents = routes.map((route) => {
            return (<Route key={route.path} path={route.path} component={route.component} exact={route.exact}/>)
        });

        return (
            <>
                <AppHeader
                    routes={routes}
                    onLogout={this.logout}
                    content={this.componentRef}
                    sidebar={this.sidebarRef}
                />
                <Sidebar.Pushable>
                    <MenuSidebar
                        ref={this.sidebarRef}
                        routes={this.state.routes}
                        currentUser={authService.principal}
                        onLogout={this.logout}
                        currentPath={this.props.location.pathname}
                    />

                    <Sidebar.Pusher className={"app-container"}>
                            <main className={"app-content " + AppFontSize.getCssClass()} ref={this.componentRef as Ref<HTMLElement>}>
                                <ErrorBox/>
                                <Switch>
                                    {routeComponents}
                                    <Redirect path="/" to={this.defaultRoute}/> {/* Default Route - Dashboard */}
                                </Switch>
                            </main>

                        <button
                          className="ui circular icon primary button infosys__scroll-to-top-btn"
                          style={{position: 'fixed', right: 15, bottom: this.state.bottomOffset + 10,
                              opacity: this.state.showScrollToTop ? '1.0' : '0.0',
                              visibility: this.state.showScrollToTop ? 'visible' : 'hidden',
                              transition: 'all 0.25s ease-in-out 0s', zIndex: 10
                          }}
                          onClick={this.scrollToTop}
                        >
                            <i aria-hidden="true" className="arrow up icon"/>
                        </button>

                        <div ref={this.bottomHiddenProbeRef} style={{
                            position: 'fixed', right: '0', bottom: 10
                        }} />
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </>
        );
    }

}

export default ContentContainer;