import axios from 'axios';

interface AppInfo {
  authenticationMethod: 'native' | 'openid_connect';
  openIdUrl?: string;
  idProviderRealmUrl?: string;
}

// initialize with default value
export const appInfo: AppInfo = {
  authenticationMethod: 'native'
};

export function updateAppInfo() {
  return new Promise((resolve, reject) => {
    axios.get('/info')
          .then((response) => {
            appInfo.authenticationMethod = response.data.authenticationMethod;
            appInfo.openIdUrl = response.data.openIdUrl;
            appInfo.idProviderRealmUrl = response.data.idProviderRealmUrl;
            resolve();
          }).catch((e) => {
            reject(e);
          })
  });
}

// @ts-ignore
window['appInfo'] = appInfo;
