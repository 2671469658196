import React, {FC, useCallback, useEffect, useState} from "react";

import GenericDataManagementService from "../../services/data-management/GenericDataManagementService";
import {withRouter} from "../../utils/compat";
import {RouteComponentProps} from "react-router";
import {SimpleCrudPage} from "../management/SimpleCrudPage";
import {AxiosResponse} from "axios";
import LoadingSpinner from "../../containers/LoadingContainer/LoadingSpinner";

import Form from '@rjsf/core';
import {
  SemanticUiArrayFieldTemplate,
  SemanticUiFieldTemplate,
  SemanticUiObjectFieldTemplate
} from './../../components/json-schema-form';
import {CreateEditModalFormProps} from "../management/CreateEditModal";
import InputType from "../../types/input-type";

interface RouteParams {
  inputType: string;
}

interface InputProps extends RouteComponentProps<RouteParams> {

}

export const Input: FC<InputProps> = withRouter((props: InputProps) => {

  const inputTypeName = props.match.params.inputType;
  const dataManagementService = new GenericDataManagementService();

  const [inputType, setInputType] = useState<InputType>();

  useEffect(() => {
    dataManagementService.getDocument("input-type", [inputTypeName]).then(
      (response: AxiosResponse<InputType>) => {
        setInputType(response.data);
      }
    );
  }, [setInputType]);

  const formComponent = useCallback((formProps: CreateEditModalFormProps) => {
    if (!inputType) return (<></>);

    return (<Form className={'ui form json-form'}
                  liveValidate={true}
                  FieldTemplate={SemanticUiFieldTemplate}
                  schema={JSON.parse(inputType.fieldsSchema)}
                  formData={formProps.data.fields}
                  ObjectFieldTemplate={SemanticUiObjectFieldTemplate}
                  ArrayFieldTemplate={SemanticUiArrayFieldTemplate}
                  showErrorList={false}
                  onChange={(data) => formProps.updateField("fields", data.formData)}
    />)
  }, [inputType]);

  if (inputType === undefined) {
    return (
        <></>
    );
  }

  return (
    <SimpleCrudPage
      dataManagementService={dataManagementService}
      entityType={"input/" + inputType.name}
      singularName={"Input: " + inputType.name}
      pluralName={"Inputs: " + inputType.name}
      formComponent={formComponent}
      defaultSortField="id"
      defaultSortDirection="ASC"
      modalSize={"fullscreen"}
      readOnly={true}

      tableColumns={[
        {title: "Id", field: "id", sortable: true},
        //{ title: "Menge", field: "amount", sortable: true },
        {title: "Version", field: "version", sortable: true},
      ]}
    />
  );

});
