import React, { FC } from "react";
import { Form } from "semantic-ui-react";
import { SimpleCrudPage } from "./SimpleCrudPage";
import { CreateEditModalFormProps } from "./CreateEditModal";
import GenericDataManagementService from "../../services/data-management/GenericDataManagementService";

interface ManagementSourcesProps {}

const CreateEditForm: FC<CreateEditModalFormProps> = (props) => {
  const { data, isCreate, updateField } = props;
  return (
    <div>
      <Form.Group widths="3">
        <Form.Input
          label="Name"
          value={data.name || ""}
          onChange={(event) => updateField("name", event.target.value)}
          readOnly={!isCreate}
        />
        <Form.Input
          label="Beschreibung"
          value={data.description || ""}
          onChange={(event) => updateField("description", event.target.value)}
        />
        <Form.Input
          label="Übergeordnet"
          value={data.parent || ""}
          onChange={(event) => updateField("parent", event.target.value)}
        />
      </Form.Group>
    </div>
  );
};

export const ManagementSources: FC<ManagementSourcesProps> = (props) => {
  const dataManagementService = new GenericDataManagementService();
  return (
    <SimpleCrudPage
      dataManagementService={dataManagementService}
      entityType="source"
      singularName="Quelle"
      pluralName="Organisationsstruktur - Emmissionsquellen"
      defaultSortField="layer"
      defaultSortDirection="ASC"
      formComponent={CreateEditForm}
      modalSize={"large"}
      tableColumns={[
        { title: "Name", field: "name", sortable: true },
        { title: "Beschreibung", field: "description", sortable: true },
        { title: "Übergeordnet", field: "parent", sortable: true },
      ]}
    />
  );
};
