import React, {FC, useCallback, useEffect, useMemo, useRef} from "react";
import { Form } from "semantic-ui-react";
import {SimpleCrudPage, TableColumn} from "./SimpleCrudPage";
import { CreateEditModalFormProps } from "./CreateEditModal";

import GenericDataManagementService from "../../services/data-management/GenericDataManagementService";
import {CodeEditor} from "../../components/CodeEditor/CodeEditor";

interface ManagementCalculationLogicProps {
    specificObject?: string,
    withoutHeader?: boolean,
    disableAdd?: boolean,
    tableColumns?: Array<TableColumn>
    addChooseCalculation?: boolean
}

const CreateEditForm: FC<CreateEditModalFormProps> = (props) => {
  const { data, isCreate, updateField } = props;

  return (
    <div>
      <Form.Group widths="2">
        <Form.Input
          label="Name"
          value={data.name || ""}
          onChange={(event) => updateField("name", event.target.value)}
          readOnly={!isCreate}
        />
        <Form.Input
            label="Version"
            type={"number"}
            value={data.version || ""}
            onChange={(event) => updateField("version", event.target.value)}
        />
        <Form.Select
          label="Sprache"
          value={data.language || ""}
          onChange={(event, data) => updateField("language", data.value)}
          options={[{ key: "groovy", value: "groovy", text: "Groovy" }]}
        ></Form.Select>
          <Form.Select
              label="Typ"
              value={data.type || ""}
              onChange={(event, data) => updateField("type", data.value)}
              options={[{ key: "input", value: "input", text: "Input" }, {key: "kpi", value: "kpi", text: "KPI"}]}
          ></Form.Select>
        <Form.TextArea
          label="Beschreibung"
          value={data.description || ""}
          onChange={(event) => updateField("description", event.target.value)}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={16}>
          <label>Code</label>
          <CodeEditor value={data.code} deps={[data.name, data.version, data.language, data.type, data.description]}  updateValue={(data)=>updateField("code", data)} language="groovy" />
        </Form.Field>
      </Form.Group>
    </div>
  );
};

export const ManagementCalculationLogic: FC<ManagementCalculationLogicProps> = (
  props
) => {
    const {specificObject, withoutHeader, disableAdd, tableColumns, addChooseCalculation} = props;
  const dataManagementService = new GenericDataManagementService();
  return (
    <SimpleCrudPage
      dataManagementService={dataManagementService}
      entityType={"calculation-logic" + (specificObject ? specificObject : "")}
      singularName="Berechnungslogik"
      pluralName="Berechnungslogiken"
      defaultSortField="name"
      defaultSortDirection="ASC"
      formComponent={CreateEditForm}
      modalSize={"large"}
      tableColumns={tableColumns ? tableColumns
          : [
        { title: "Name", field: "name", sortable: true },
        { title: "Beschreibung", field: "description", sortable: true },
        { title: "Sprache", field: "language", sortable: true },
      ]}
      withoutHeader={withoutHeader}
      disableAdd={disableAdd}
      addChooseCalculation={addChooseCalculation}
    />
  );
};
