import React, { FC, useEffect, useState } from "react";
import { Button, Dimmer, Loader, Modal } from "semantic-ui-react";
import { GenericDataManagement } from "../../services/data-management/DataManagementType";

interface CreateEditModalProps {
  entityType: string;
  dataManagementService: GenericDataManagement;
  deleteEntity: Record<string, any> | null;
  open: boolean;

  text?: string;

  onClose: () => void;
  onDeleted?: () => void;
}

export const DeleteModal: FC<CreateEditModalProps> = (props) => {
  const [deleting, setDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);

  const confirmDelete = () => {
    if (!props.deleteEntity) {
      return;
    }

    setDeleting(true);
    setDeleteError(null);

    props.dataManagementService
      .deleteDocument(props.entityType, props.deleteEntity)
      .then(() => {
        setDeleting(false);
        props.onDeleted && props.onDeleted();
      })
      .catch((error) => {
        setDeleteError(error.message);
        setDeleting(false);
      });
  };

  useEffect(() => {
    if (!props.open) {
      return;
    }

    setDeleting(false);
    setDeleteError(null);
  }, [props.open]);

  return (
    <Modal open={props.open}>
      <Modal.Header>Datensatz löschen</Modal.Header>
      <Modal.Content style={{ position: "relative" }}>
        <p>
          {props.text ||
            "Sind Sie sicher, dass Sie diesen Datensatz löschen möchten?"}
        </p>

        {/* Saving Overlay */}
        {deleting && (
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={props.onClose} disabled={deleting}>
          Abbrechen
        </Button>
        <Button
          type="button"
          color="red"
          loading={deleting}
          disabled={deleting}
          onClick={confirmDelete}
        >
          Ja, löschen
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
