import * as React from "react";
import {Message, Icon, SemanticICONS} from "semantic-ui-react";

interface ErrorBoxProps {}

interface ErrorBoxState {
    visible: boolean,
    icon: SemanticICONS,
    heading: string,
    text: string
}

class ErrorBox extends React.Component<ErrorBoxProps, ErrorBoxState> {


    //STATIC CONTENT
    public static instance: ErrorBox;

    public static show(heading:string, text:string) {
        if (ErrorBox.instance !== null && typeof ErrorBox.instance !== 'undefined') {
            ErrorBox.instance.show(heading, text);
        } else {
            console.debug("Unable to show ErrorBox - there is currently no instance available");
        }
    }

    public static reset() {
        if (ErrorBox.instance !== null) {
            ErrorBox.instance.reset();
        }
    }

    /////

    constructor(props: ErrorBoxProps) {
        super(props);

        ErrorBox.instance = this;

        this.state = {
            visible: false,
            icon: "thumbs down outline",
            heading: "",
            text: ""
        };

        this.onDismiss = this.onDismiss.bind(this);
    }

    componentDidMount() {

    }

    onDismiss() {
        this.setState({visible: false});
    }

    show(heading:string, text:string) {
        this.setState({
           visible: true,
           heading: heading,
           text: text
        });
    }

    reset() {
        this.setState({
            visible: false
        })
    }

    render() {
        if (this.state.visible) {
            return (
                <Message
                    negative
                    onDismiss={this.onDismiss}
                    icon
                >
                    <Icon name={this.state.icon} />
                    <Message.Content>
                        <Message.Header>{this.state.heading}</Message.Header>
                        <p>{this.state.text}</p>
                    </Message.Content>
                </Message>
            );
        }
        return (<></>);
    }
}

export default ErrorBox;