import * as React from "react";
import {Button, Dropdown, Icon, Modal, DropdownItemProps} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {useDocumentListPageState} from "../services/listManagement";
import {GenericDataManagement} from "../services/data-management/DataManagementType";

interface ChooseCalculationProps {
    dataManagementService: GenericDataManagement;
}

const ChooseCalculation = (props: ChooseCalculationProps) => {
    const {dataManagementService} = props;

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<DropdownItemProps[]>([]);

    const listPage = useDocumentListPageState(
        dataManagementService,
        "calculation-logic",
        "name",
        "ASC"
    );

    // useEffect(() => {
    //     let newOptions: DropdownItemProps[] = [];
    //     listPage.items.forEach(item => {
    //         newOptions.push({
    //             key: item.name,
    //             text: item.name,
    //             value: item.name
    //         });
    //     });
    //     setOptions(newOptions);
    // }, [listPage]);

    return (
        <>
            <Button primary onClick={() => setModalOpen(true)}>
                <Icon name="plus" /> Berechnungslogik hinzufügen
            </Button>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                centered={true}
            >
                <Modal.Header>
                    Berechnungslogik auswählen
                </Modal.Header>
                <Modal.Content>
                    <Dropdown
                        placeholder={"Berechnugslogik"}
                        selection
                        fluid
                        options={listPage.items.map(item => {
                            return {
                                key: item.name,
                                text: item.name,
                                value: item.name
                            };
                        })}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button type="button" onClick={() => setModalOpen(false)}>
                        Abbrechen
                    </Button>
                        <Button
                            type="button"
                            primary
                            onClick={() => setModalOpen(false)}
                        >
                            Hinzufügen
                        </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default ChooseCalculation;
