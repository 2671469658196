import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Dimmer,
  Form,
  Loader,
  Modal,
  TextArea,
} from "semantic-ui-react";
import _set from "lodash/set";
import _cloneDeep from "lodash/cloneDeep";
import { GenericDataManagement } from "../../services/data-management/DataManagementType";
import { toast } from "react-toastify";
interface CreateEditModalProps {
  entityType: string;

  editEntity?: Record<string, any>;

  open: boolean;
  modalHeader?: string;

  size: "mini" | "tiny" | "small" | "large" | "fullscreen" | undefined;

  dataManagementService: GenericDataManagement;

  readOnly?: boolean;

  formComponent?: FC<CreateEditModalFormProps>;

  onClose: () => void;
  onSaved?: () => void;

  simulateLoading?: boolean;
}

export interface CreateEditModalFormProps {
  data: Record<string, any>;

  isCreate: boolean;

  updateField: (field: string, value: any) => void;
  updateData: (data: Record<string, any>) => void;
}

const JsonForm: FC<CreateEditModalFormProps> = (props) => {
  const { data, updateData } = props;
  return (
    <div>
      <TextArea
        rows="10"
        style={{ width: "100%" }}
        value={JSON.stringify(data, null, 2)}
        onChange={(event) => {
          try {
            updateData(JSON.parse(event.target.value));
          } catch (e) {}
        }}
      />
    </div>
  );
};

export const CreateEditModal: FC<CreateEditModalProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState<string | null>(null);

  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState<string | null>(null);

  const [data, setData] = useState<Record<string, any>>({});

  const { readOnly } = props;

  const updateField = (field: string, value: any) => {
    if (readOnly) {
      return;
    }
    const newData = _cloneDeep(data);
    _set(newData, field, value);
    setData(newData);
  };

  const updateData = (data: Record<string, any>) => {
    if (readOnly) {
      return;
    }
    setData({ ...data });
  };

  const FormComponent = props.formComponent || JsonForm;

  const submitSave = async () => {
    if (readOnly) {
      return;
    }

    setSaving(true);
    setSaveError(null);

    const savePromise = props.dataManagementService.createOrUpdateDocument(
      props.entityType,
      data
    );

    // Wait for a few seconds to simulate workload in background
    if (props.simulateLoading) {
      //await new Promise((resolve) => setTimeout(resolve, 10000));
      const resolveAfterFewSec = new Promise((resolve) =>
        setTimeout(resolve, 60000)
      );
      toast.promise(resolveAfterFewSec, {
        pending: "Umgebung wird erstellt...",
        success: "Umgebung erfolgreich erstellt!",
        error: "Umgebung konnte nicht erstellt werden.",
      });
    }

    savePromise
      .then(() => {
        setSaving(false);
        props.onSaved && props.onSaved();
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          setSaveError("Eintrag mit dieser ID existiert bereits.");
        } else {
          setSaveError(error.message);
        }
        setSaving(false);
      });
  };

  useEffect(() => {
    if (!props.open) {
      return;
    }

    setLoading(true);
    setLoadError(null);
    setSaving(false);
    setSaveError(null);

    if (!props.editEntity) {
      setData({});
      setLoading(false);
      return;
    }

    setData(props.editEntity);
    setLoading(false);

  }, [props.editEntity, props.open]);

  return (
    <Modal
      open={props.open}
      size={props.size}
      onClose={props.onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      centered={false}
    >
      {!readOnly && (
        <Modal.Header>
          {props.modalHeader ?? "Eintrag"}
          {!props.editEntity ? " erstellen" : " bearbeiten"}
        </Modal.Header>
      )}
      {readOnly && <Modal.Header>Details</Modal.Header>}
      <Modal.Content style={{ position: "relative" }} scrolling={true}>
        {/* Loading */}
        {loading && (
          <div>
            <Dimmer active inverted>
              <Loader active />
            </Dimmer>
            <div style={{ height: 100 }}></div>
          </div>
        )}
        {/* Loading Error */}
        {!loading && loadError && <div>Error: {loadError}</div>}

        {/* Form */}
        {!loading && !loadError && (
          <Form>
            <FormComponent
              data={data}
              isCreate={!props.editEntity}
              updateData={updateData}
              updateField={updateField}
            />
          </Form>
        )}

        {/* Saving Overlay */}
        {saving && (
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        )}
      </Modal.Content>
      <Modal.Actions>
        <div style={{ float: "left", color: "red" }}>{saveError}</div>
        <Button type="button" onClick={props.onClose} disabled={saving}>
          Abbrechen
        </Button>
        {!loading && !loadError && !readOnly && (
          <Button
            type="button"
            primary
            loading={saving}
            disabled={saving}
            onClick={submitSave}
          >
            Speichern
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};
