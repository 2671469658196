import React, {FC, useCallback, useState} from "react";
import {Accordion, Button, Checkbox, Divider, Grid, Header, Icon, Segment} from "semantic-ui-react";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";
import {ManagementCalculationLogic} from "../views/management/ManagementCalculationLogic";
import {Metadata} from "../views/metadata/Metadata";

interface EditFactorProps extends RouteComponentProps {}

export const EditFactor: FC<EditFactorProps> = withRouter((props: EditFactorProps) => {
    const [consumptionFactorsAvailable, setConsumptionFactorsAvailable] = useState(false);
    const [consumptionFactorsActive, setConsumptionFactorsActive] = useState<boolean>(false);
    const [consumptionFormulasActive, setConsumptionFormulasActive] = useState<boolean>(false);

    const [distanceFactorsActive, setDistanceFactorsActive] = useState<boolean>(false);
    const [distanceFormulasActive, setDistanceFormulasActive] = useState<boolean>(false);

    const navigate = useCallback((path) => {
        props.history.push(path);
    }, []);

    return(
        <div>
            <Header as="h1">Editieren Formeln & Faktoren</Header>
            <Grid columns={2} verticalAlign={"middle"}>
                <Grid.Row>
                    <Grid.Column>
                        <Header as="h2">Scope 1 - Mobile Verbrennung</Header>
                    </Grid.Column>
                    <Grid.Column>
                        <Button floated={"right"} secondary onClick={props.history.goBack}>Zurück</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>
            <Segment>
                <Header as={"h4"}>Verbrauchsbhängige Berechnung</Header>
                <Accordion styled fluid>
                    <Accordion.Title
                        active={consumptionFactorsActive}
                        onClick={() => setConsumptionFactorsActive(!consumptionFactorsActive)}
                    >
                        <Icon name={"dropdown"}/>
                        Faktoren
                    </Accordion.Title>
                    <Accordion.Content active={consumptionFactorsActive}>
                        <Metadata history={props.history} location={props.location} match={{...props.match, params: {metadataType: "mobile-verbrennung-verbrauchsorientiert-emissionsfaktoren"}}} headerName={"Kraftstoffemissionsfaktoren"}
                                  tableColumns={[
                                      { title: "Kraftstoff", field: "id", sortable: true },
                                      { title: "Well to wheel Faktor", field: "fields.wellToWheel", sortable: true },
                                      { title: "Tank to wheel Faktor", field: "fields.tankToWheel", sortable: true },
                                      { title: "Einheit", field: "fields.incomingUnit", sortable: true },
                                      { title: "Emissionseinheit", field: "fields.emissionNumerator", sortable: true },
                                      { title: "Kommentar", field: "fields.comment", sortable: true },
                                  ]}
                        />
                    </Accordion.Content>
                    <Accordion.Title
                        active={consumptionFormulasActive}
                        onClick={() => setConsumptionFormulasActive(!consumptionFormulasActive)}
                    >
                        <Icon name={"dropdown"}/>
                        Formeln - Bsp.: Verbrauch x Emissionsfaktor
                    </Accordion.Title>
                    <Accordion.Content active={consumptionFormulasActive}>
                        <ManagementCalculationLogic specificObject={"/einfache-berechnung-menge-mal-faktor/1"} withoutHeader={true}
                                                    tableColumns={[
                                                        { title: "Name", field: "name", sortable: true },
                                                        { title: "Beschreibung", field: "description", sortable: true },
                                                        { title: "Sprache", field: "language", sortable: true },
                                                        { title: "Aktiv", field: "active", sortable: false, cellRenderer: (data) => <Checkbox defaultChecked/> }
                                                    ]}
                                                    addChooseCalculation={true}
                        />
                    </Accordion.Content>
                </Accordion>
            </Segment>
            <Segment>
                <Header as={"h4"}>Distanzbhängige Berechnung</Header>
                <Accordion styled fluid>
                    <Accordion.Title
                        active={distanceFactorsActive}
                        onClick={() => setDistanceFactorsActive(!distanceFactorsActive)}
                    >
                        <Icon name={"dropdown"}/>
                        Faktoren
                    </Accordion.Title>
                    <Accordion.Content active={distanceFactorsActive}>
                        <Metadata history={props.history} location={props.location} match={{...props.match, params: {metadataType: "mobile-verbrennung-distanzorientiert-emissionsfaktoren"}}} headerName={"Transportemissionsfaktoren"}
                                  tableColumns={[
                                      { title: "Kraftstoff", field: "id", sortable: true },
                                      { title: "Well to wheel Faktor", field: "fields.wellToWheel", sortable: true },
                                      { title: "Tank to wheel Faktor", field: "fields.tankToWheel", sortable: true },
                                      { title: "Einheit", field: "fields.incomingUnit", sortable: true },
                                      { title: "Emissionseinheit", field: "fields.emissionNumerator", sortable: true },
                                      { title: "Kommentar", field: "fields.comment", sortable: true },
                                  ]}
                        />
                    </Accordion.Content>
                    <Accordion.Title
                        active={distanceFormulasActive}
                        onClick={() => setDistanceFormulasActive(!distanceFormulasActive)}
                    >
                        <Icon name={"dropdown"}/>
                        Formeln - Bsp.: Distanz x Emissionsfaktor
                    </Accordion.Title>
                    <Accordion.Content active={distanceFormulasActive}>
                        <ManagementCalculationLogic specificObject={"/einfache-berechnung-menge-mal-faktor/1"} withoutHeader={true}
                                                    tableColumns={[
                                                        { title: "Name", field: "name", sortable: true },
                                                        { title: "Beschreibung", field: "description", sortable: true },
                                                        { title: "Sprache", field: "language", sortable: true },
                                                        { title: "Aktiv", field: "active", sortable: false, cellRenderer: (data) => <Checkbox defaultChecked/> }
                                                    ]}
                                                    addChooseCalculation={true}
                        />
                    </Accordion.Content>
                </Accordion>
            </Segment>
        </div>
    );
});