import * as React from 'react';
import {Button, Dropdown, Flag, Icon, Menu, Responsive} from 'semantic-ui-react';
import Popout from 'react-popout';
import AppRoute from '../../AppRoute';
import ExportContextHolder from "../../../../lib/ExportContextHolder";
import PrintableTable from "../PrintableTable/PrintableTable";
import AppContainer from "../../containers/AppContainer/AppContainer";
import authService from "../../services/AuthService";
import i18n, {resources} from "../../../../locale/i18n";
import './AppHeader.css';
import fileDownload from 'js-file-download';


const translator = i18n.t.bind(i18n);
const logo = require('../../logo.png');

interface IAppHeaderProps {
    onLogout: Function;
    routes: AppRoute[];
    content: any,
    sidebar: any
}

interface IAppHeaderState {
    printState: boolean,
    exporting: boolean;
}

class AppHeader extends React.Component<IAppHeaderProps, IAppHeaderState> {

    public static instance: AppHeader;
    private readonly popupRef: React.Ref<any>;

    constructor(props: IAppHeaderProps) {
        super(props);

        AppHeader.instance = this;

        this.state = {
            printState: false,
            exporting: false
        };

        this.popupRef = React.createRef();

        this.onChangeLanguage = this.onChangeLanguage.bind(this);
        this.closePrintWindow = this.closePrintWindow.bind(this);

        ExportContextHolder.reloadHeaderFunc = this.forceUpdate.bind(this);
    }

    public componentDidMount() {
    }

    public toggleSidebar() {
        this.props.sidebar.current.toggle();
    }

    public onChangeLanguage(language: string) {
        i18n.changeLanguage(language);
        AppContainer.instance.forceUpdate();
    }

    public closePrintWindow() {
        this.setState({
            printState: false
        });
    }

    private renderDesktopItems() {
        return (
            <>
                <Menu.Item className="menu-item-logo" header={true}>
                    <Button className={"menu-button"} onClick={this.toggleSidebar.bind(this)} icon={"bars"}>
                    </Button>
                    <img src={logo} className="App-logo" alt="logo"/>
                </Menu.Item>

                {
                    /*

                 <Responsive minWidth={768} as={Menu.Item} position="right">
                    <Button className={"menu-button"} content={translator("Common.Print")} icon={"print"} onClick={this.exportPrint.bind(this)}  disabled={ExportContextHolder.enabled !== true}></Button>
                    <Button loading={this.state.exporting} className={"menu-button"} content={translator("Common.CSV")} icon={"file excel"} onClick={this.exportCsv} disabled={ExportContextHolder.enabled !== true}></Button>
                </Responsive>
                     */
                }
                <Responsive minWidth={768} as={Menu.Item} position="right">

                    {/*
                        Object.keys(resources).map(
                            (lng: String) => {
                                if (lng === 'en')
                                    lng = "gb";

                                //@ts-ignore
                                return <Flag name={lng} className={"languageChangeFlag"} onClick={()=> {this.onChangeLanguage(lng)}}/>
                            }
                        )
                    */}
                    {authService.loggedIn ? (
                        <Dropdown
                            trigger={<span><Icon name={"user"}/> <strong>{ authService.principal?.name || ""} <Icon name={"caret down"}/></strong></span>}
                            icon={null}
                            pointing="top right">
                            <Dropdown.Menu>
                                <Dropdown.Item text={"Logout"} icon="sign out" onClick={() => this.props.onLogout()}/>
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <Button primary onClick={() => authService.redirectToLoginForm()}>{translator("Auth.Login")}</Button>
                    )}
                </Responsive>
            </>
        )
    }


    public render() {
        return (
            <>
                <Menu borderless={true} fluid={true} compact={true} size="small" className="app-header-menu">
                    {this.renderDesktopItems()}
                </Menu>
            </>
        );
    }
}

export default AppHeader;