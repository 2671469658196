import React, {FC} from 'react';
import {Header, Image} from "semantic-ui-react";

interface ManagementReportsProps {

}

export const ManagementReports: FC<ManagementReportsProps> = (props) => {
    return (
        <div>
            <Header as='h1'>Verwaltung: Berichte</Header>
            <Image src='https://react.semantic-ui.com/images/wireframe/paragraph.png'/>
        </div>


    );
};


