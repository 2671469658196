import React, { FC } from "react";
import { SimpleCrudPage } from "../management/SimpleCrudPage";
import GenericDataManagementService from "../../services/data-management/GenericDataManagementService";

export const CompensationPlanting: FC<{}> = (props) => {
  const dataManagementService = new GenericDataManagementService();
  return (
    <SimpleCrudPage
      dataManagementService={dataManagementService}
      entityType="temp/comp-project-plantings"
      singularName="Pflanzvorgang"
      pluralName="Pflanzvorgänge"
      defaultSortField="data.plantationDate"
      defaultSortDirection="DESC"
      readOnly
      tableColumns={[
        { title: "Id", field: "data.plantationId", sortable: true },
        {
          title: "Datum",
          field: "data.plantationDate",
          sortable: true,
          format: "date",
        },
        { title: "Project Id", field: "data.projectId", sortable: true },
        { title: "Project Name", field: "data.projectName", sortable: true },
        { title: "Pflanzmenge", field: "data.plantedAmount", sortable: true },
      ]}
    />
  );
};
