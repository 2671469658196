import './polyfills';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './locale/i18n';
import AppContainer from './modules/App/containers/AppContainer/AppContainer';
import { HashRouter} from 'react-router-dom';
import {unregister} from './registerServiceWorker';
import 'semantic-ui-css/semantic.min.css';
import { CookiesProvider } from 'react-cookie';

import './index.css'; // IMPORTANT: import index css AFTER semantic ui css
import 'react-datepicker/dist/react-datepicker.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


ReactDOM.render(
    <CookiesProvider>
        <HashRouter>
            <>
                <AppContainer />
                <ToastContainer />
            </>
        </HashRouter>
    </CookiesProvider>,
    document.getElementById('root') as HTMLElement
);

unregister();