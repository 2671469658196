import * as React from "react";
import { useMemo, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  Icon,
  Progress,
  Segment,
  Table,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import {
  KpiChart,
  LineChartComponent,
} from "../components/Charts/ChartComponents";
import { endOfDay, format, parse, startOfDay, subDays } from "date-fns";
import { useKpi } from "../components/Charts/KpiApi";
import _get from "lodash/get";
import {RouteComponentProps} from "react-router";

const DashboardScope2 = (props: RouteComponentProps) => {
  const [filterDateFrom, setFilterDateFrom] = useState(
    startOfDay(Date.parse("2021-01-02"))
  );
  const [filterDateTo, setFilterDateTo] = useState(endOfDay(Date.parse("2023-12-31")));

  const totalEmissionsPerScope = useKpi({
    kpiType: "co2",
    grouping: "fields.scope",
    dateFrom: filterDateFrom,
    dateTo: filterDateTo,
    stepSize: "TOTAL",
    filters: [{ fieldName: "type", operator: "equal", value: "co2" }],
  });

  const totalEmissionsPerGHGCategory = useKpi({
    kpiType: "co2",
    grouping: "fields.ghgCategory",
    dateFrom: filterDateFrom,
    dateTo: filterDateTo,
    stepSize: "TOTAL",
    filters: [{ fieldName: "fields.scope", operator: "equal", value: 2 }],
  });

  const yearlyEmissionsPerGHGCategory = useKpi({
    kpiType: "co2",
    grouping: "fields.ghgCategory",
    dateFrom: filterDateFrom,
    dateTo: filterDateTo,
    stepSize: "TOTAL",
    filters: [{ fieldName: "fields.scope", operator: "equal", value: 2 }],
  });

  return (
    <>
      <h1>Dashboard - CCF - Scope 2</h1>
      <br />
      <Grid>
        <Grid.Row columns={5}>
          <Grid.Column>
            <Segment inverted color={"yellow"}>
              <h2>
                <Icon name={"industry"} />
                Scope 2
              </h2>
              <Divider></Divider>
              <h2>{(totalEmissionsPerScope.data[0]?.["2"] / 1000).toFixed(3)|| "0"} t</h2>
            </Segment>
          </Grid.Column>
          <Grid.Column/>
          <Grid.Column/>
          <Grid.Column/>
          <Grid.Column>
            <Button floated={"right"} primary onClick={props.history.goBack}>Zurück</Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment>
              <h2>CO2 Emissionen (t) nach GHG-Kategorie</h2>
              <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>GHG Kategorie</Table.HeaderCell>
                        <Table.HeaderCell>Emissionen</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                  {Object.keys(totalEmissionsPerGHGCategory.data?.[0] || {}).map(
                      (category) => {
                        return (
                            <Table.Row>
                              <Table.Cell>
                                {category}
                              </Table.Cell>
                              <Table.Cell>
                                {(
                                    _get(totalEmissionsPerGHGCategory.data?.[0], category) / 1000
                                ).toFixed(3).replace(".", ",") || " t"}
                                t
                              </Table.Cell>
                            </Table.Row>
                        );
                      }
                  )}
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <h2>Anteil je GHG-Kategorie</h2>
              <KpiChart
                  kpiType="co2"
                  chartType="pie"
                  grouping="fields.ghgCategory"
                  stepSize="TOTAL"
                  dateFrom={filterDateFrom}
                  dateTo={filterDateTo}
                  filters={[{ fieldName: "fields.scope", operator: "equal", value: 2 }]}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default DashboardScope2;
