import React from "react";
import {Button, Dimmer, Loader, Modal, Pagination, Table} from "semantic-ui-react";
import GenericDataManagementService from "../../services/data-management/GenericDataManagementService";
import TransactionReferenceData from "../../types/transaction-reference-data";
import {useDocumentListPageState} from "../../services/listManagement";

interface TransactionReferenceDataSelectionModalProps {
  open?: boolean;

  onCancel?(): void;
  onSelect?(t: TransactionReferenceData): void;
}

const dataService = new GenericDataManagementService();

function TransactionReferenceDataSelectionModal(props: TransactionReferenceDataSelectionModalProps) {
  const listPage = useDocumentListPageState(dataService, 'transaction-reference-data', 'description','ASC');

  const { loading, items: referenceTransactions } = listPage;


  const selectRow = (transaction: TransactionReferenceData) => {
    if (props.onSelect) {
      props.onSelect(transaction);
    }
  };

  const handleCancelButtonClick = () => {
    if(props.onCancel) {
      props.onCancel();
    }
  }

  const renderTable = () => {
    return <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Typ</Table.HeaderCell>
            <Table.HeaderCell>Beschreibung</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {referenceTransactions.map(t => (<Table.Row key={t.id}>
            <Table.Cell>{t.id}</Table.Cell>
            <Table.Cell>{t.type}</Table.Cell>
            <Table.Cell>{t.description}</Table.Cell>
            <Table.Cell textAlign="right">
              <Button size="tiny" type="button" onClick={() => selectRow(t)}>Übernehmen</Button>
            </Table.Cell>
          </Table.Row>)
          )}
        </Table.Body>
      </Table>

      <Pagination
        totalPages={listPage.totalPages}
        activePage={listPage.page + 1}
        onPageChange={(e, data) =>
          listPage.changePage(+(data.activePage || 1) - 1)
        }
      />
    </>
  };

  return <Modal open={props.open}>
    <Modal.Header>
      Referenzdaten wählen
    </Modal.Header>
    <Modal.Content>
      {loading
        ? (<Dimmer active inverted>
          <Loader inverted/>
        </Dimmer>)
        : renderTable()}
    </Modal.Content>
    <Modal.Actions>
      <Button type="button" onClick={() => handleCancelButtonClick()}>Abbrechen</Button>
    </Modal.Actions>
  </Modal>;
}

export default TransactionReferenceDataSelectionModal;