import React, {FC, useCallback} from 'react';
import {Grid, Header, Icon, Image, Segment} from "semantic-ui-react";
import routes from "../../routes";
import {RouteComponentProps} from "react-router";
import i18n from "../../../../locale/i18n";

const t = i18n.t.bind(i18n);
interface CompensationProps extends RouteComponentProps {

}

export const Compensation: FC<CompensationProps> = (props) => {

  const navigate = useCallback((path) => {
    props.history.push(path);
  }, []);

  return (
    <div>
      <Header as='h1'>Kompensation</Header>

      <Grid>
        {routes.filter((r)=> r.title === "Compensate")?.pop()?.subRoutes?.map((subRoute) =>
          (<Grid.Column width={4}>
            <a onClick={navigate.bind(null, subRoute.path)}>
              <Segment textAlign={"center"}>
                <Header icon style={{color: "#B31D2C", cursor:"pointer"}}>
                  <Icon name={subRoute.icon}></Icon>
                  <p>{t("Route." +subRoute.title || "")}</p>
                </Header>
              </Segment>
            </a>
          </Grid.Column>)
        )}
      </Grid>

    </div>
  );
};
