import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {
  Divider, Dropdown, Form,
  Grid,
  Icon, Input,
  Progress,
  Segment,
  Table,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import {
  BarChartComponent,
  KpiChart,
  LineChartComponent, PieChartComponent,
} from "../components/Charts/ChartComponents";
import _ from "lodash";
import { endOfDay, format, parse, startOfDay, subDays } from "date-fns";
import { useKpi } from "../components/Charts/KpiApi";
import GenericDataManagementService from "../services/data-management/GenericDataManagementService";
import {KPI} from "../types/kpi";
import {BarChart} from "recharts";

const DashboardProduct = () => {
  const [filterDateFrom, setFilterDateFrom] = useState(
      startOfDay(Date.parse("2021-01-02"))
  );

  const dataManagementService = new GenericDataManagementService();

  const [filterDateTo, setFilterDateTo] = useState(endOfDay(Date.parse("2023-12-31")));

  const [product, setProduct] = useState<MetaData>({} as MetaData);

  const [kpi, setKpi] = useState<KPI>({} as KPI);
  const [cultivationFactor, setCultivationFactor] = useState<number>(0.114232);
  const [processingFactor, setProcessingFactor] = useState<number>(0.378);
  const [packagingFactor, setPackagingFactor] = useState<number>(0.128486);
  const [productionFactor, setProductionFactor] = useState<number>(0.620719);


  useEffect(()=>{
    dataManagementService.getDocument("kpi/co2-by-product", ["GSIN43000000000001-1-transport","1"]).then(
        (response) => {
          setKpi(response.data);
        });
  }, [setKpi]);

  useEffect(()=>{
    dataManagementService.getDocument("metadata/product", ["GSIN43000000000001", "1"]).then(
        (response) => {
          setProduct(response.data);
        });
  }, [setProduct]);

  useEffect( () => {
      setCultivationFactor(Number(product.fields?.Product_Sustain.filter((sustain: any)=> sustain.type === "cultivation")[0].CO2_Aequivalent.replace(",", ".")));
  }, [setCultivationFactor,product]);

  useEffect( () => {
    setProcessingFactor(Number(product.fields?.Product_Sustain.filter((sustain: any)=> sustain.type === "processing")[0].CO2_Aequivalent.replace(",", ".")));
  }, [setProcessingFactor,product]);

  useEffect( () => {
    setPackagingFactor(Number(product.fields?.Product_Sustain.filter((sustain: any)=> sustain.type === "packaging")[0].CO2_Aequivalent.replace(",", ".")));
  }, [setPackagingFactor,product])

  useEffect( () => {
    setProductionFactor(Number(product.fields?.Product_Sustain.filter((sustain: any)=> sustain.type === "production")[0].CO2_Aequivalent.replace(",", ".")));
  }, [setProductionFactor,product])

    const totalProductionEmissionsPerProduct = useKpi({
      kpiType: "co2-by-product",
      grouping: "fields.productNo",
      dateFrom: filterDateFrom,
      dateTo: filterDateTo,
      stepSize: "TOTAL",
      filters: [
        {fieldName: "fields.type", operator: "unequal", value: "production"}],
    });

    const totalCultivationEmissionsPerProduct = useKpi({
      kpiType: "co2-by-product",
      grouping: "fields.productNo",
      dateFrom: filterDateFrom,
      dateTo: filterDateTo,
      stepSize: "TOTAL",
      filters: [
        {fieldName: "fields.type", operator: "equal", value: "cultivation"}],
    });

    const totalProcessingEmissionsPerProduct = useKpi({
      kpiType: "co2-by-product",
      grouping: "fields.productNo",
      dateFrom: filterDateFrom,
      dateTo: filterDateTo,
      stepSize: "TOTAL",
      filters: [
        {fieldName: "fields.type", operator: "equal", value: "processing"}],
    });

    const totalPackagingEmissionsPerProduct = useKpi({
      kpiType: "co2-by-product",
      grouping: "fields.productNo",
      dateFrom: filterDateFrom,
      dateTo: filterDateTo,
      stepSize: "TOTAL",
      filters: [
        {fieldName: "fields.type", operator: "equal", value: "packaging"}],
    });

    const totalTransportEmissionsPerProduct = useKpi({
      kpiType: "co2-by-product",
      grouping: "fields.productNo",
      dateFrom: filterDateFrom,
      dateTo: filterDateTo,
      stepSize: "TOTAL",
      filters: [
        {fieldName: "fields.type", operator: "equal", value: "transport"}],
    });

    const totalEmissionsPerProduct = useKpi({
      kpiType: "co2-by-product",
      grouping: "fields.productNo",
      dateFrom: filterDateFrom,
      dateTo: filterDateTo,
      stepSize: "YEARLY",
      filters: [
        {fieldName: "fields.type", operator: "unequal", value: "production"}],
    });

    const totalEmissionsPerType = useKpi({
      kpiType: "co2-by-product",
      grouping: "fields.type",
      dateFrom: filterDateFrom,
      dateTo: filterDateTo,
      stepSize: "YEARLY",
      filters: [
        {fieldName: "fields.type", operator: "unequal", value: "production"}],
    });

    return (
        <>
          <h1>Dashboard - PCF</h1>
          <br/>
          <Segment>
            <h3>Filter</h3>
            <Grid>
              <Grid.Row columns={5}>
                <Grid.Column>
                  Produkt:

                  <Dropdown
                      placeholder='Auswählen...'
                      fluid
                      readOnly={true}
                      value={"GSIN43000000000001"}
                      selection
                      options={[{text: "GSIN43000000000001", value: "GSIN43000000000001"},]}
                  />
                </Grid.Column>
                <Grid.Column>
                  Jahr:

                  <Form.Input readOnly={true} value={"2022"}></Form.Input>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Grid>
            <Grid.Row columns={5}>
              <Grid.Column>
                <Segment inverted color={"yellow"}>
                  <h2>
                    <Icon name={"industry"}/>
                    Gesamte Emissionen
                  </h2>
                  <Divider></Divider>
                  <h2>
                    {totalProductionEmissionsPerProduct.data[0] ? _.get(totalProductionEmissionsPerProduct.data[0], "GSIN43000000000001")?.toFixed(0) : "0"} kg
                  </h2>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment inverted color={"yellow"}>
                  <h2>
                    <Icon name={"tree"}/>
                    Anbau
                  </h2>
                  <Divider></Divider>
                  <h2>
                    {totalCultivationEmissionsPerProduct.data[0] ? _.get(totalCultivationEmissionsPerProduct.data[0], "GSIN43000000000001")?.toFixed(0) : "0"} kg

                  </h2>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment inverted color={"yellow"}>
                  <h2>
                    <Icon name={"factory"}/>
                    Verarbeitung
                  </h2>
                  <Divider></Divider>
                  <h2>
                    {totalProcessingEmissionsPerProduct.data[0] ? _.get(totalProcessingEmissionsPerProduct.data[0], "GSIN43000000000001")?.toFixed(0) : "0"} kg
                  </h2>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment inverted color={"yellow"}>
                  <h2>
                    <Icon name={"box"}/>
                    Verpackung
                  </h2>
                  <Divider></Divider>
                  <h2>
                    {totalPackagingEmissionsPerProduct.data[0] ? _.get(totalPackagingEmissionsPerProduct.data[0], "GSIN43000000000001")?.toFixed(0) : "0"} kg
                  </h2>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment inverted color={"yellow"}>
                  <h2>
                    <Icon name={"truck"}/>
                    Transport
                  </h2>
                  <Divider></Divider>
                  <h2>
                    {totalTransportEmissionsPerProduct.data[0] ? _.get(totalTransportEmissionsPerProduct.data[0], "GSIN43000000000001")?.toFixed(0) : "0"} kg
                  </h2>
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Column width={8}>
              <Segment>
                <h2>Gesamtemissionen (kg)</h2>
                <LineChartComponent
                    data={totalEmissionsPerProduct.data}
                    lines={[
                      {dataKey: "GSIN43000000000001", name: "GSIN43000000000001"},
                    ]}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment>
                <h2>Emissionen nach Kategorie (kg)</h2>
                <LineChartComponent data={totalEmissionsPerType.data}
                    lines={[
                      {dataKey: "cultivation", name: "Anbau"},
                      {dataKey: "processing", name: "Verarbeitung"},
                      {dataKey: "packaging", name: "Verpackung"},
                      {dataKey: "transport", name: "Transport"},
                    ]}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment>
                <h2>CO2-Footprint nach Lieferung ({((cultivationFactor * 6) + (processingFactor * 6) + (packagingFactor * 6)+ (kpi.amount / 400)).toFixed(2)} kg pro Gebinde)</h2>

                <PieChartComponent data={[{
                    "culivation": cultivationFactor * 6,
                    "processing": processingFactor * 6,
                    "packaging": packagingFactor * 6,
                    "transport": (kpi.amount / 400),
                }]}

                />

              </Segment>
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment>
                <h2>CO2-Footprint nach Lieferung ({((cultivationFactor) + (processingFactor ) + (packagingFactor)+ (kpi.amount / 400/6)).toFixed(2)} kg pro Flasche)</h2>

                <PieChartComponent data={[{
                  "culivation": cultivationFactor,
                  "processing": processingFactor,
                  "packaging": packagingFactor,
                  "transport": (kpi.amount / 400/6),
                }]}

                />

              </Segment>
            </Grid.Column>
          </Grid>
        </>
    );
  };

export default DashboardProduct;
