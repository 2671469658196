import React, {FC, useCallback, useEffect, useState} from "react";

import GenericDataManagementService from "../../services/data-management/GenericDataManagementService";
import {withRouter} from "../../utils/compat";
import {RouteComponentProps} from "react-router";
import {SimpleCrudPage, TableColumn} from "../management/SimpleCrudPage";
import {AxiosResponse} from "axios";

import {Form as SForm} from "semantic-ui-react";

import Form from '@rjsf/core';
import {SemanticUiArrayFieldTemplate, SemanticUiFieldTemplate, SemanticUiObjectFieldTemplate} from './../../components/json-schema-form';
import {CreateEditModalFormProps} from "../management/CreateEditModal";
import MetadataType from "../../types/metadata-type";

interface RouteParams{
    metadataType: string;
}

interface MetadataProps extends RouteComponentProps<RouteParams>{
    withoutHeader?: boolean,
    disableAdd?: boolean,
    headerName?: string,
    tableColumns?: Array<TableColumn>
}

export const Metadata: FC<MetadataProps> = withRouter((props: MetadataProps) => {

  const metadataTypeName = props.match.params.metadataType;
  const dataManagementService = new GenericDataManagementService();

  const [metadataType, setMetadataType] = useState<MetadataType>();

  useEffect(() => {
      dataManagementService.getDocument("metadata-type", [metadataTypeName]).then(
            (response: AxiosResponse<MetadataType>) => {
                setMetadataType(response.data);
            }
      );
  }, [setMetadataType]);

  const formComponent = (formProps: CreateEditModalFormProps) => {
      if (!metadataType) return (<></>);

      return (
          <SForm>
              <SForm.Group>
                  <SForm.Field>
                      <SForm.Input
                          label="Id"
                          value={formProps.data.id || ""}
                          onChange={(event: any, data: any) => formProps.updateField("id", data.value)}
                          readOnly={!formProps.isCreate}
                      />
                  </SForm.Field>
              </SForm.Group>
              <Form className={'ui form json-form'}
                    liveValidate={true}
                    FieldTemplate={SemanticUiFieldTemplate}
                    schema={JSON.parse(metadataType.fieldsSchema)}
                    formData={formProps.data.fields}
                    ObjectFieldTemplate={SemanticUiObjectFieldTemplate}
                    ArrayFieldTemplate={SemanticUiArrayFieldTemplate}
                    showErrorList={false}
                    onChange={(data) => formProps.updateField("fields", data.formData)}
              />
          </SForm>)
  };

  if (metadataType === undefined) {
      return (
          <></>
      );
  }

  return (
      <SimpleCrudPage
          dataManagementService={dataManagementService}
          entityType={"metadata/" + metadataType.name}
          singularName={"Berechnungsdaten: " + metadataType.name}
          pluralName={"Berechnungsdaten: " + metadataType.name}
          formComponent={formComponent}
          defaultSortField="id"
          defaultSortDirection="ASC"
          modalSize={"fullscreen"}

          tableColumns={props.tableColumns ? props.tableColumns
              : [
            { title: "Id", field: "id", sortable: true },
            //{ title: "Menge", field: "amount", sortable: true },
            { title: "Version", field: "version", sortable: true },
          ]}
          withoutHeader={props.withoutHeader}
          disableAdd={props.disableAdd}
          headerName={props.headerName}
      />
  );

});
