import React, { FC } from "react";
import { SimpleCrudPage } from "../management/SimpleCrudPage";
import GenericDataManagementService from "../../services/data-management/GenericDataManagementService";

interface CompensationProjectProps {}

export const CompensationProject: FC<CompensationProjectProps> = (props) => {
  const dataManagementService = new GenericDataManagementService();
  return (
    <SimpleCrudPage
      dataManagementService={dataManagementService}
      entityType="temp/comp-project-reservations"
      singularName="Kompensationsprojekt"
      pluralName="Kompensationsprojekte"
      defaultSortField="matchingTimestamp"
      defaultSortDirection="DESC"
      readOnly
      tableColumns={[
        { title: "Id", field: "data.projectId", sortable: true },
        {
          title: "Datum",
          field: "data.matchingTimestamp",
          sortable: true,
          format: "date",
        },
        { title: "Name", field: "data.projectName", sortable: true },
        { title: "Ort", field: "data.projectState", sortable: true },
        { title: "Typ", field: "data.projectType", sortable: true },
        { title: "Menge", field: "data.orderAmount", sortable: true },
      ]}
    />
  );
};
