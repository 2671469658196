// @ts-ignore
import i18n from "i18next";
// @ts-ignore
import languageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

const resources = {
    de: require('./de.json'),
    en: require('./en.json'),
    //nl: require('./nl.json')
};

i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        resources,
        interpolation: {
            escapeValue: false
        },
        debug: process.env.NODE_ENV !== 'production',
        keySeparator: ".",
        fallbackLng: "de"

    });



export {resources};
export default i18n;