import React, {FC, useCallback, useEffect, useState} from "react";

import GenericDataManagementService from "../../services/data-management/GenericDataManagementService";
import {withRouter} from "../../utils/compat";
import {RouteComponentProps} from "react-router";
import {SimpleCrudPage} from "../management/SimpleCrudPage";
import {CreateEditModalFormProps} from "../management/CreateEditModal";
import {CodeEditor} from "../../components/CodeEditor/CodeEditor";
import {Accordion, Form, Icon} from "semantic-ui-react";
import {JSONSchema7} from "@optum/json-schema-editor/dist/JsonSchemaEditor.types";
import JsonSchemaEditor from "@optum/json-schema-editor";

interface RouteParams{
    metadataType: string;
}

interface MetadataTypesProps extends RouteComponentProps<RouteParams>{

}


const CreateEditForm: FC<CreateEditModalFormProps> = (props) => {
    const { data, isCreate, updateField } = props;

    const [schemaOpen, setSchemaOpen] = useState<boolean>(false);

    const updateSchema = useCallback((schema: any) => {
        setSchema(schema);
    }, [props]);

    const [schema, setSchema] = useState<JSONSchema7 | undefined>(undefined);

    useEffect(() => {
        props.data.fieldsSchema && !schema && setSchema(JSON.parse(props.data.fieldsSchema));
    }, [props, props.data, data.fieldsSchema]);

    useEffect(() => {
        updateField("fieldsSchema", schema)
    }, [schema]);

    return (
        <div>
            <Form.Group widths="2">
                <Form.Input
                    label="Name"
                    value={data.name || ""}
                    onChange={(event) => updateField("name", event.target.value)}
                    readOnly={!isCreate}
                />
                <Form.Input
                    label="Version"
                    type={"number"}
                    value={data.version || ""}
                    onChange={(event) => updateField("version", event.target.value)}
                />
                <Form.TextArea
                    label="Beschreibung"
                    value={data.description || ""}
                    onChange={(event) => updateField("description", event.target.value)}
                />
            </Form.Group>

            <Form.Group widths={16}>
                <Accordion fluid styled>
                    <Form.Field width={16}>
                        <Accordion.Title active={schemaOpen}
                                         index={0}
                                         onClick={() => setSchemaOpen(!schemaOpen)}>
                            <Icon name='dropdown' />
                            <label>Schema</label>
                        </Accordion.Title>
                        <Accordion.Content active={schemaOpen}>
                            {
                                (props.data.fieldsSchema && schema) || isCreate ? <JsonSchemaEditor data={schema} onSchemaChange={updateSchema} /> : <></>
                            }
                        </Accordion.Content>
                    </Form.Field>
                </Accordion>
            </Form.Group>

            {/*TODO: toggle button for switch between JsonSchemaEditor and CodeEditor*/}
            {/*<Form.Group>*/}
            {/*    <Form.Field width={16}>*/}
            {/*        <label>Schema</label>*/}
            {/*        <CodeEditor value={data.fieldsSchema} updateValue={(data) =>updateField("fieldsSchema", data)} deps={[data.name, data.version, data.description]} language="json" />*/}
            {/*    </Form.Field>*/}
            {/*</Form.Group>*/}
        </div>
    );
};
export const MetadataTypes: FC<MetadataTypesProps> = withRouter((props: MetadataTypesProps) => {

  const dataManagementService = new GenericDataManagementService();

  return (
      <SimpleCrudPage
          dataManagementService={dataManagementService}
          entityType="metadata-type"
          singularName="Berechnungsdatentyp"
          pluralName="Berechnungsdatentypen"
          defaultSortField="name"
          defaultSortDirection="ASC"
          modalSize={"fullscreen"}
          formComponent={CreateEditForm}
          readOnly={true}
          tableColumns={[
              { title: "Name", field: "name", sortable: true, format: "navlink:/metadata/:name" },
              { title: "Beschreibung", field: "description", sortable: true },
              { title: "Version", field: "version", sortable: true },
          ]}
      />
  );

});
