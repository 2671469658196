import React, {FC, useEffect, useState} from "react";
import { Form } from "semantic-ui-react";
import { SimpleCrudPage } from "./SimpleCrudPage";
import { CreateEditModalFormProps } from "./CreateEditModal";
import GenericDataManagementService from "../../services/data-management/GenericDataManagementService";
import Source from "../../types/source";
import TransactionType from "../../types/transaction-type";

interface ManagementRoleProps {}

const CreateEditForm: FC<CreateEditModalFormProps> = (props) => {
    const { data, isCreate, updateField } = props;

    const dataService = new GenericDataManagementService();

    const [sources, setSources] = useState<Source[]>([]);
    const [transactionTypes, setTransactionTypes] = useState<TransactionType[]>([]);

    useEffect(() => {
        dataService.listDocuments("source", 0, "name", "ASC").then((sources) => {
            setSources(sources.data.content as Source[]);
        });
    }, [setSources]);

    useEffect(() => {
        dataService.listDocuments("transaction-type", 0, "name", "ASC").then((transactionTypes) => {
            setTransactionTypes(transactionTypes.data.content as TransactionType[]);
        });
    }, [setTransactionTypes]);

    return (
        <div style={{minHeight: "500px"}}>
            <Form.Group widths="2" >
                <Form.Input
                    label="Name"
                    value={data.name || ""}
                    onChange={(event) => updateField("name", event.target.value)}
                    readOnly={!isCreate}
                />
                <Form.TextArea
                    label="Beschreibung"
                    value={data.description || ""}
                    onChange={(event) => updateField("description", event.target.value)}
                />
            </Form.Group>
            <Form.Group widths="2">
                <Form.Select
                    label="Berechtigungen (Quelle)"
                    multiple={true}
                    value={data.sourcePermissions || []}
                    options={sources.map((source) => ({
                        key: source.name,
                        value: source.name,
                        text: source.name,
                    })) || []}
                    onChange={(event, data) => updateField("sourcePermissions", data.value)}
                />
                <Form.Select
                    label="Berechtigungen (Kategorien)"
                    multiple={true}
                    value={data.categoryPermissions || []}
                    options={transactionTypes.map((transactionType) => ({
                        key: transactionType.name,
                        value: transactionType.name,
                        text: transactionType.name,
                    })) || []}
                    onChange={(event, data) => updateField("categoryPermissions", data.value)}
                />
            </Form.Group>
        </div>
    );
};

export const ManagementRole: FC<ManagementRoleProps> = (props) => {
    const dataManagementService = new GenericDataManagementService();
    return (
        <SimpleCrudPage
            dataManagementService={dataManagementService}
            entityType="role"
            singularName="Rolle"
            pluralName="Rollen"
            defaultSortField="name"
            defaultSortDirection="ASC"
            formComponent={CreateEditForm}
            tableColumns={[
                { title: "Name", field: "name", sortable: true },
                { title: "Beschreibung", field: "description", sortable: true },
            ]}
        />
    );
};
