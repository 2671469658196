import RestClientInstance from "../../../../lib/RestClient";
import { GenericDataManagement, SortDirection } from "./DataManagementType";

class GenericDataManagementService implements GenericDataManagement {
  // This function retrieves the schema for a given entity type.
  async getSchema(entityType: string) {
    return await RestClientInstance.get(
      `${entityType}/__schema`
    );
  }

  // This function updates or creates a document with the provided data.
  async createOrUpdateDocument(entityType: string, data: any) {
    return await RestClientInstance.put(
      `${entityType}/`,
      data
    );
  }

  // This function deletes a specific document.
  deleteDocument = async (entityType: string, entity: Record<string, any>) => {
    return await RestClientInstance.delete_withPayload(
      `${entityType}/`,
        entity
    );
  };

  // This function gets a specific document based on its entityType and id.
  getDocument = async (entityType: string, ids: string[]) => {

    let urlIdPath = ids.join("/");

    return await RestClientInstance.get(`${entityType}/${urlIdPath}`);
  };

  // This function gets a list of documents based on certain parameters.
  listDocuments = async (
    entityType: string,
    page: number,
    sortField: string,
    sortDirection: SortDirection
  ) => {
    return await RestClientInstance.get(`${entityType}`, {
      page,
      sort: `${sortField},${sortDirection}`
    });
  };
}

export default GenericDataManagementService;
