import React from "react";
import {
    Bar,
    BarChart,
    CartesianGrid, Cell,
    Legend,
    Line,
    LineChart, Pie, PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {KpiApiParameters, useKpi} from "./KpiApi";

//
// This file contains opinionated default chart components based on recharts for use e.g. with KPI data.
//

//
// Common interfaces
//

/**
 * Common props for all chart types
 */
interface ChartComponentProps {
    data: Record<string, any>[];

    width?: number | string;
    height?: number | string;
}

/**
 * Common props for all time series charts
 */
interface TimeSeriesChartProps extends ChartComponentProps{
    xAxisKey?: string;
}

/**
 * Properties for a data set (e.g. line or bar) in a time series chart
 */
interface DataSet {
    name: string;
    dataKey: string;
    strokeColor?: string;
    fillColor?: string;
}

//
// Components
//

interface LineChartComponentProps extends TimeSeriesChartProps {
    lines?: DataSet[];
}

/**
 * Line chart component that accepts data from useKpi without further transformation.
 * If the lines prop is not set, the component will render a line for each key found in the data.
 *
 * @param props
 * @constructor
 */
export const LineChartComponent = (props: LineChartComponentProps) => {
    const { data } = props;

    const dataSets: DataSet[] = props.lines || getDefaultDataSets(data);

    return <ResponsiveContainer
        width={props.width || '95%'}
        height={props.height || 500}
    >
        <LineChart
            data={data}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                dataKey={props.xAxisKey || 'dates'}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            { dataSets.map((dataSet, idx) => (
                <Line
                    key={idx}
                    name={dataSet.name}
                    dataKey={dataSet.dataKey}
                    stroke={dataSet.strokeColor || deriveColorFromIndex(idx)}
                />
            ))}
        </LineChart>
    </ResponsiveContainer>;
};


interface BarChartComponentProps extends TimeSeriesChartProps {
    bars?: DataSet[];
}

/**
 * Bar chart component that accepts data from useKpi without further transformation.
 * If the bars prop is not set, the component will render a bar for each key found in the data.
 *
 * @param props
 * @constructor
 */
export const BarChartComponent = (props: BarChartComponentProps) => {
    const { data } = props;

    const dataSets: DataSet[] = props.bars || getDefaultDataSets(data);

    return <ResponsiveContainer
        width={props.width || '95%'}
        height={props.height || 500}
    >
        <BarChart
            data={data}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                dataKey={props.xAxisKey || 'dates'}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            { dataSets.map((dataSet, idx) => (
                <Bar
                    key={idx}
                    name={dataSet.name}
                    dataKey={dataSet.dataKey}
                    stroke={dataSet.strokeColor || deriveColorFromIndex(idx)}
                    fill={dataSet.strokeColor || deriveLightColorFromIndex(idx)}
                />
            ))}
        </BarChart>
    </ResponsiveContainer>;
}


interface PieChartComponentProps extends ChartComponentProps {
}

/**
 * Pie chart component that accepts data from useKpi without further transformation.
 * This should be used with stepSize = TOTAL only.
 *
 * @param props
 * @constructor
 */
export const PieChartComponent = (props: PieChartComponentProps) => {
    const { data } = props;

    const dataTransformed = data.length > 0
        ? Object.keys(data[0]).map((key, idx) => ({
            name: key,
            value: data[0][key],
        }))
        : [];

    return <ResponsiveContainer
        width={props.width || '95%'}
        height={props.height || 500}
    >
        <PieChart
        >
            <Tooltip />
            <Legend />
            <Pie
                data={dataTransformed}
                nameKey={'name'}
                dataKey={'value'}>
                {dataTransformed.map((entry, idx) => (
                    <Cell
                        key={idx}
                        fill={deriveLightColorFromIndex(idx)}
                        stroke={deriveColorFromIndex(idx)}
                    />
                ))}
            </Pie>
        </PieChart>
    </ResponsiveContainer>;
};

interface KpiChartProps extends KpiApiParameters {
    chartType: 'line' | 'bar' | 'pie';
    dataSets?: DataSet[];
}

/**
 * Stateful chart component that combines the KPI API with one of the predefined chart components.
 *
 * @param props
 * @constructor
 */
export const KpiChart = (props: KpiChartProps) => {
    const { chartType } = props;

    const { data } = useKpi({
        kpiType: props.kpiType,
        dateFrom: props.dateFrom,
        dateTo: props.dateTo,
        grouping: props.grouping,
        stepSize: props.stepSize,
        aggregationType: props.aggregationType,
        filters: props.filters
    });

    if (chartType === 'pie' && props.stepSize !== 'TOTAL') {
        throw new Error('Pie charts can only be used with step size TOTAL');
    }

    switch (chartType) {
        case 'line':
            return <LineChartComponent
                data={data}
                lines={props.dataSets}
            />;
        case 'bar':
            return <BarChartComponent
                data={data}
                bars={props.dataSets}
            />;
        case 'pie':
            return <PieChartComponent
                data={data}
            />;
    }
};

//
// Helpers
//

const defaultColors = [
    '#36a2eb',
    '#ff6384',
    '#ff9f40',
    '#ffcd56',
    '#4bc0c0',
    '#9966ff',
    '#c9cbcf',
];

const defaultColorsLight = [
    'hsl(204,82%,67%)',
    'hsl(347,100%,79%)',
    'hsl(30,100%,73%)',
    'hsl(42,100%,77%)',
    'hsl(180,48%,62%)',
    'hsl(260,100%,80%)',
    'hsl(220,6%,90%)',
];

function deriveColorFromIndex(idx: number) {
    return defaultColors[idx % defaultColors.length];
}

function deriveLightColorFromIndex(idx: number) {
    return defaultColorsLight[idx % defaultColors.length];
}

function getDefaultDataSets(data: Record<string, any>[]): DataSet[] {
    return data[0] ? Object.keys(data[0])
            .filter(key => key !== 'dates')
            .map((key, idx) => ({
                name: key,
                dataKey: key,
                })
        ) : [];
}