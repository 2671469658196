import React, {FC, useCallback, useEffect, useState} from "react";

import GenericDataManagementService from "../../services/data-management/GenericDataManagementService";
import {withRouter} from "../../utils/compat";
import {RouteComponentProps} from "react-router";
import {SimpleCrudPage} from "../management/SimpleCrudPage";
import {AxiosResponse} from "axios";

import Form from '@rjsf/core';
import {SemanticUiArrayFieldTemplate, SemanticUiFieldTemplate, SemanticUiObjectFieldTemplate} from './../../components/json-schema-form';
import {CreateEditModalFormProps} from "../management/CreateEditModal";
import TransactionType from "../../types/transaction-type";
import {format} from "date-fns";
import {Form as SForm} from "semantic-ui-react";

interface RouteParams{
    transactionType: string;
}

interface TransactionProps extends RouteComponentProps<RouteParams>{

}

export const Transaction: FC<TransactionProps> = withRouter((props: TransactionProps) => {

  const transactionTypeName = props.match.params.transactionType;
  const dataManagementService = new GenericDataManagementService();

  const [transactionType, setTransactionType] = useState<TransactionType>();

  useEffect(() => {
      dataManagementService.getDocument("transaction-type", [transactionTypeName]).then(
            (response: AxiosResponse<TransactionType>) => {
                setTransactionType(response.data);
            }
      );
  }, [setTransactionType]);

  const formComponent = useCallback((formProps: CreateEditModalFormProps) => {
      if (!transactionType) return (<></>);
      const [transactionFormData, setTransactionFormData] = useState({
          // amount: formProps.data.amount,
          ...formProps.data.fields
      });

      useEffect(() => {
          // formProps.updateField("amount", transactionFormData.amount);
          // formProps.updateField("fields", transactionFormData.fields);
      }, [transactionFormData]);

      const transactionFormSchema = JSON.parse(transactionType.fieldsSchema)
      // transactionFormSchema.properties = {
      //     amount: {
      //       type: "number"
      //     },
      //     ...transactionFormSchema.properties
      // };

      return (
          <SForm>
              <SForm.Group>
                  <SForm.Field>
                      <SForm.Input
                          label="Menge"
                          value={formProps.data.amount || ""}
                          onChange={(event: any, data: any) => formProps.updateField("amount", data.value)}
                      />
                  </SForm.Field>
                  <SForm.Field>
                      <SForm.Input
                          label="Kommentar"
                          value={formProps.data.notes || ""}
                          onChange={(event: any, data: any) => formProps.updateField("notes", data.value)}
                      />
                  </SForm.Field>
              </SForm.Group>
              <Form className={'ui form json-form'}
                    liveValidate={true}
                    FieldTemplate={SemanticUiFieldTemplate}
                    schema={JSON.parse(transactionType.fieldsSchema)}
                    formData={formProps.data.fields}
                    ObjectFieldTemplate={SemanticUiObjectFieldTemplate}
                    ArrayFieldTemplate={SemanticUiArrayFieldTemplate}
                    showErrorList={false}
                    onChange={(data) => formProps.updateField("fields", data.formData)}
              />
          </SForm>)

      // return (<Form className={'ui form json-form'}
      //           liveValidate={true}
      //           FieldTemplate={SemanticUiFieldTemplate}
      //           schema={transactionFormSchema}
      //           formData={transactionFormData}
      //           ObjectFieldTemplate={SemanticUiObjectFieldTemplate}
      //           ArrayFieldTemplate={SemanticUiArrayFieldTemplate}
      //           showErrorList={false}
      //           onChange={(data) => setTransactionFormData(data)}
      // />)
  }, [transactionType]);

  if (transactionType === undefined) {
      return (
          <></>
      );
  }

  return (
      <SimpleCrudPage
          dataManagementService={dataManagementService}
          entityType={"transaction/" + transactionType.name}
          singularName={"Transaktion"}
          pluralName={"Transaktionen: " + transactionType.name}
          formComponent={formComponent}
          defaultSortField="id"
          defaultSortDirection="ASC"
          modalSize={"fullscreen"}

          tableColumns={[
            { title: "Id", field: "id", sortable: true },
            { title: "Quelle", field: "source", sortable: true },
            { title: "Datum", field: "timestampReadable", sortable: true,
              cellRenderer: data => data['timestampReadable'] ? format(new Date(data['timestampReadable']), 'dd.MM.yyyy HH:mm') : '' },
            { title: "Menge", field: "amount", sortable: true },
            { title: "Version", field: "version", sortable: true },
            { title: "Status", field: "fields.status", sortable: true },
            { title: "Typ", field: "category", sortable: true, cellRenderer: (data) => data.category || "manuell" },
            { title: "Kommentar", field: "fields.comment", sortable: true }
          ]}
      />
  );

});
