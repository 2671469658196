import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GenericDataManagement } from "./data-management/DataManagementType";

// This is a custom Hook that fetches and manages the schema for a specific entityType.
export const useSchema = (
  entityType: string,
  documentManagementService: GenericDataManagement
) => {
  const [loading, setLoading] = useState(false);

  const [schema, setSchema] = useState<Record<string, any> | undefined>(
    undefined
  );

  // useCallback returns a memoized version of the callback that only changes if one of the dependencies has changed.
  // This is useful when passing callbacks to optimized child components that rely on reference equality to prevent unnecessary renders.
  const loadSchema = useCallback(async () => {
    // Before making the API call, we clear any previously set 'schema' state and set 'loading' state to true.
    setSchema(undefined);
    setLoading(true);

    // The API call is made to fetch the schema data for the given entityType.
    const response = await documentManagementService.getSchema(entityType);

    // After the API call, we update our 'schema' state with the response data and set 'loading' state to false.
    setSchema(response.data);
    setLoading(false);

    // This function will be re-created when 'entityType' changes.
  }, [entityType]);

  // useEffect is a Hook that allows you to perform side effects in function components.
  // It runs after the first render and after every update.
  useEffect(() => {
    // We call our 'loadSchema' function.
    loadSchema().then(() => {});

    // 'loadSchema' is a dependency for this effect,
    // meaning that the effect will only re-run if 'loadSchema' changes.
  }, [loadSchema]);

  // The Hook returns an object containing the 'loading' state and 'schema' state.
  // Components using this Hook will have access to these values.
  return {
    loading,
    schema,
  };
};

// TODO: Could be split into multiple smaller hooks but for poc its ok
// This is another custom Hook that manages the state and actions for document listing, sorting and pagination.
export const useDocumentListPageState = (
  documentManagementService: GenericDataManagement,
  entityType: string,
  defaultSortField: string,
  defaultSortDirection: "ASC" | "DESC"
) => {
  // Initialize a set of state variables to hold the items, filter object, total pages, total items and loading status.
  const [items, setItems] = useState<any[]>([]);

  const [filterObject, setFilterObject] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const [loading, setLoading] = useState(false);

  // Using hooks from react-router-dom to get the current location and history object.
  const location = useLocation();
  const history = useHistory();

  // Get the current query string from the URL.
  const queryString = location.search;
  const parsed = new URLSearchParams(queryString);

  // Get the current sort field, sort direction and page from the URL or default to the given parameters.
  const sortField = parsed.get("sortField") || defaultSortField;
  const sortDirection =
    (parsed.get("sortDirection") as "ASC" | "DESC") || defaultSortDirection;
  const page = +(parsed.get("page") || "0");

  // Define a callback function to change the page, updating the URL.
  const changePage = useCallback(
    (page: number) => {
      const newUrlParams = new URLSearchParams(queryString);
      newUrlParams.set("page", `${page}`);

      history.replace(location.pathname + "?" + newUrlParams.toString());
    },
    [history, location.pathname, queryString]
  );

  // Define a callback function to toggle the sort field and direction, updating the URL.
  const toggleSort = useCallback(
    (field: string) => {
      let newSortField = field;
      let newSortDirection: string;

      if (sortField === field) {
        newSortDirection = sortDirection === "ASC" ? "DESC" : "ASC";
      } else {
        newSortDirection = "ASC";
      }

      const newUrlParams = new URLSearchParams(queryString);
      newUrlParams.set("sortField", newSortField);
      newUrlParams.set("sortDirection", newSortDirection);
      history.replace(location.pathname + "?" + newUrlParams.toString());
    },
    [history, location.pathname, queryString, sortDirection, sortField]
  );

  // Define a callback function to load the current page based on the current parameters.
  const loadCurrentPage = useCallback(async () => {
    setLoading(true);
    const response = await documentManagementService.listDocuments(
      entityType,
      page,
      sortField,
      sortDirection
    );
    console.log(response)
    if (response.data.content) {
      setItems(response.data.content)
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalElements);
    } else {
      setItems([response.data])
      setTotalPages(1);
      setTotalItems(1);
    }
    setLoading(false);
  }, [entityType, page, sortDirection, sortField]);

  // Load the current page when the component mounts or the 'loadCurrentPage' function changes.
  useEffect(() => {
    loadCurrentPage().then(() => {});
  }, [loadCurrentPage]);

  return {
    items,
    loading,
    page,
    totalPages,
    totalItems,
    sortField,
    sortDirection,
    changePage,
    toggleSort,
    loadCurrentPage,
  };
};
