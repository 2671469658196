import * as React from 'react';

// @ts-ignore
import * as pick from 'object.pick';
import {JSONSchema7} from 'json-schema';
import {ObjectFieldTemplateProps} from '@rjsf/core';
import {Header, Segment} from 'semantic-ui-react';
import AceEditor from 'react-ace';

function groupFields (
    schema: {[k: string]: JSONSchema7 | boolean } | undefined,
    properties: ObjectFieldProperty[],
    rawObject: {[key: string]: any}
) {
    let groups = {
        simple: new Array<ObjectFieldProperty>(),
        complex: new Array<ObjectFieldProperty>(),
        unhandled: undefined
    };
    if (!schema || !properties) {
        return groups;
    }
    let unhandledKeys = Object.keys(rawObject).filter((k) => k !== '__translations');

    for (let property of properties) {
        unhandledKeys = unhandledKeys.filter((key) => key !== property.name);
        let fieldSchema = schema[property.name];
        if (typeof fieldSchema !== 'boolean' && (fieldSchema.type === 'array' || fieldSchema.type === 'object' || !!fieldSchema['$ref'])) {
            groups.complex.push(property);
        } else {
            groups.simple.push(property);
        }
    }
    if (unhandledKeys.length) {
        groups.unhandled = pick(rawObject, unhandledKeys);
    }
    return groups;
}

type CustomObjectFieldTemplateProps = ObjectFieldTemplateProps & {
    onChange: Function
};

type ObjectFieldProperty = {
    content: React.ReactElement<any>,
    name: string;
    disabled: boolean;
    readonly: boolean;
};

export default ((props: CustomObjectFieldTemplateProps) => {
    const MAX_PROPS_IN_ROW = 5;
    // @ts-ignore
    let groups = groupFields(props.schema.properties, props.properties, props.formData);
    let data = JSON.stringify(groups.unhandled);
    let renderSimpleProp = (element: ObjectFieldProperty) => (
        <div className="property-wrapper" key={element.name}>
            {React.cloneElement(element.content, {
                onTranslationChange: changeTranslationData,
                translations: props.formData.__translations || {}
            })}
        </div>
    );
    let renderComplexProp = (element: ObjectFieldProperty) =>
        <div className="property-wrapper" key={element.name}>{element.content}</div>;

    function changeUnhandledData (_data: string) {
        data = !_data || !_data.trim().length ? '{}' : _data;
    }

    function changeTranslationData (translations: object) {
        props.onChange({ ...props.formData, __translations: translations });
    }

    function writeUnhandledData () {
        try {
            props.onChange(Object.assign({},
                pick(props.formData, groups.simple.map((g) => g.name)),
                pick(props.formData, groups.complex.map((g) => g.name)),
                props.formData.__translations,
                JSON.parse(data)
            ));
        } catch (err) {
            // Invalid JSON
        }
    }

    return (
        <div>
            <Segment.Group>
                <Segment>
                    <div className={`simple-props ${groups.simple.length <= MAX_PROPS_IN_ROW ? 'compact' : 'large'}`}>
                        {groups.simple.map(renderSimpleProp)}
                    </div>
                    <div className="complex-props">
                        {groups.complex.map(renderComplexProp)}
                    </div>
                    {groups.unhandled ?
                        <div className="unhandled-props">
                            <Header size="small">Unbekannte Eigenschaften:</Header>
                            <AceEditor
                                mode="json"
                                theme="chrome"
                                value={JSON.stringify(groups.unhandled, null, 4)}
                                editorProps={{$blockScrolling: Infinity}}
                                onChange={changeUnhandledData}
                                onBlur={writeUnhandledData}
                                width="100%"
                                height="100px"
                            />
                        </div>
                    : null}
                </Segment>
            </Segment.Group>
        </div>
    );
}) as React.StatelessComponent<ObjectFieldTemplateProps>;
