import * as React from 'react';
import './LoadingContainer.css';

import LoadingSpinner from './LoadingSpinner';

type LoadingContainerProps = {
    showLogo: boolean
}

type LoadingContainerState = {
    showLogo: boolean
}

class LoadingContainer extends React.Component<LoadingContainerProps, LoadingContainerState> {
    constructor(props: {showLogo: false}) {
        super(props);

        this.state = {
            showLogo: props.showLogo
        };
    }

    render() {
        return (
            <div className={"responsiveBox"}>
                <LoadingSpinner />
            </div>
        );
    }
}

export default LoadingContainer;
