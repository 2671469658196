import React, {FC, useCallback, useEffect, useState} from "react";

import GenericDataManagementService from "../../services/data-management/GenericDataManagementService";
import {withRouter} from "../../utils/compat";
import {RouteComponentProps} from "react-router";
import {SimpleCrudPage} from "../management/SimpleCrudPage";
import {CreateEditModalFormProps} from "../management/CreateEditModal";
import {CodeEditor} from "../../components/CodeEditor/CodeEditor";
import {Form} from "semantic-ui-react";

interface RouteParams{
    inputType: string;
}

interface InputTypesProps extends RouteComponentProps<RouteParams>{

}


const CreateEditForm: FC<CreateEditModalFormProps> = (props) => {
    const { data, isCreate, updateField } = props;


    return (
        <div>
            <Form.Group widths="2">
                <Form.Input
                    label="Name"
                    value={data.name || ""}
                    onChange={(event) => updateField("name", event.target.value)}
                    readOnly={!isCreate}
                />
                <Form.Input
                    label="Version"
                    type={"number"}
                    value={data.version || ""}
                    onChange={(event) => updateField("version", event.target.value)}
                />
                <Form.TextArea
                    label="Beschreibung"
                    value={data.description || ""}
                    onChange={(event) => updateField("description", event.target.value)}
                />
            </Form.Group>

            <Form.Group>
                <Form.Field width={16}>
                    <label>Schema</label>
                    <CodeEditor value={data.fieldsSchema} updateValue={(data) =>updateField("fieldsSchema", data)} deps={[data.name, data.version, data.description]} language="json" />
                </Form.Field>
            </Form.Group>
        </div>
    );
};
export const InputTypes: FC<InputTypesProps> = withRouter((props: InputTypesProps) => {

  const dataManagementService = new GenericDataManagementService();

  return (
      <SimpleCrudPage
          dataManagementService={dataManagementService}
          entityType="input-type"
          singularName="Eingabetyp"
          pluralName="Eingabetypen"
          defaultSortField="name"
          defaultSortDirection="ASC"
          modalSize={"large"}
          formComponent={CreateEditForm}
          readOnly={true}
          tableColumns={[
              { title: "Name", field: "name", sortable: true, format: "navlink:/input/:name" },
              { title: "Beschreibung", field: "description", sortable: true },
              { title: "Version", field: "version", sortable: true },
          ]}
      />
  );

});
