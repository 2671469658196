import * as React from 'react';
import {Button, Grid, Segment} from 'semantic-ui-react';
import {ArrayFieldTemplateProps} from '@rjsf/core';

export default ((props) => {
    return (
        <Segment.Group className={props.className} stacked={true}>
            {props.items && props.items.map(element => (
                <Segment key={element.index}>
                    <Grid>
                        <Grid.Row className="array-item">
                            <Grid.Column className="array-item-content">
                                {element.children}
                            </Grid.Column>
                            {element.hasToolbar && <Grid.Column className="array-item-buttons">
                                <Button
                                    icon={'trash alternate outline'}
                                    type="button"
                                    negative={true}
                                    size="small"
                                    disabled={!element.hasRemove || element.disabled || element.readonly}
                                    onClick={element.onDropIndexClick(element.index)}
                                />
                                <Button
                                    icon={'chevron up'}
                                    type="button"
                                    size="small"
                                    disabled={!element.hasMoveUp || element.disabled || element.readonly}
                                    onClick={element.onReorderClick(
                                        element.index,
                                        element.index - 1
                                    )}
                                />
                                <Button
                                    icon={'chevron down'}
                                    type="button"
                                    size="small"
                                    disabled={!element.hasMoveDown || element.disabled || element.readonly}
                                    onClick={element.onReorderClick(
                                        element.index,
                                        element.index + 1
                                    )}
                                />
                            </Grid.Column>}
                        </Grid.Row>
                    </Grid>
                </Segment>
            ))}
            {props.canAdd && (
                <Segment textAlign={'right'}>
                    <Button type="button" icon={'plus'} onClick={props.onAddClick}/>
                </Segment>
            )}
        </Segment.Group>
    );
}) as React.StatelessComponent<ArrayFieldTemplateProps>;
