import * as React from 'react';
import {FieldTemplateProps} from '@rjsf/core';
import {Form} from 'semantic-ui-react';

export default ((props) => {
    return (
        <Form.Field className={props.classNames}>
            {props.label && <label htmlFor={props.id}>{props.label} {props.required ? '*' : null}</label>}
            {props.children}
            {props.help}
        </Form.Field>
    );
}) as React.StatelessComponent<FieldTemplateProps>;
