import React, { FC, useCallback, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Grid,
  Header,
  Label,
  Segment,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { endOfDay, format, parse, startOfDay } from "date-fns";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
interface CreatePCFProps extends RouteComponentProps {}

export const CreatePCF: FC<CreatePCFProps> = withRouter(
  (props: CreatePCFProps) => {
    const [created, setCreated] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [contactPersonName, setContactPersonName] = useState<string>("");
    const [department, setDepartment] = useState<string>("");

    const navigate = useCallback((path) => {
      props.history.push(path);
    }, []);

    const [filterDateFrom, setFilterDateFrom] = useState(
      startOfDay(Date.parse("2023-01-01"))
    );
    const [filterDateTo, setFilterDateTo] = useState(
      endOfDay(Date.parse("2023-12-31"))
    );

    return (
      <div>
        <Header as="h1">{created ? "Anpassung" : "Anlage"} neuer PCF</Header>
        <Divider />
        <Grid>
          <Grid.Row columns={4} verticalAlign={"middle"}>
            <Grid.Column>
              <Form>
                <Form.Field width={16}>
                  <label>Name</label>
                  <input
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column>
              <Form>
                <Form.Field width={16}>
                  <label>Ansprechpartner</label>
                  <input
                    value={contactPersonName}
                    onChange={(event) =>
                      setContactPersonName(event.target.value)
                    }
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column>
              <Form>
                <Form.Field width={16}>
                  <label>Abteilung</label>
                  <input
                    value={department}
                    onChange={(event) => setDepartment(event.target.value)}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column className="right floated">
              <Button
                floated={"right"}
                secondary
                onClick={props.history.goBack}
              >
                Zurück
              </Button>
              <Button
                floated={"right"}
                primary
                onClick={() => setCreated(true)}
                disabled={false}
              >
                {created ? "Speichern" : "Anlegen"}
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3}>
            <Grid.Column width={10}>
              <Segment>
                <Header as="h3">Produktdaten</Header>
                <Divider />
                <Form>
                  <Grid columns={2}>
                    <Grid.Row>
                      <Grid.Column>
                        <Grid columns={2}>
                          <Grid.Row>
                            <Grid.Column width={4} verticalAlign="middle">
                              <b>Produktname</b>
                            </Grid.Column>
                            <Grid.Column width={12}>
                              <Form.Select
                                value={"Wein Martigny 1920-Jahrgang 2021"}
                                onChange={() => {}}
                                options={[
                                  {
                                    key: "wein-2021",
                                    value: "Wein Martigny 1920-Jahrgang 2021",
                                    text: "Wein Martigny 1920-Jahrgang 2021",
                                  },
                                ]}
                              ></Form.Select>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={4} verticalAlign="middle">
                              <b>GSIN</b>
                            </Grid.Column>
                            <Grid.Column width={8}>
                              <Form.Input
                                value={"GSIN43000000000001"}
                                onChange={() => {}}
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={4} verticalAlign="middle">
                              <b>Charge</b>
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <Form.Select
                                value={"2021-X-230517"}
                                onChange={() => {}}
                                options={[
                                  {
                                    key: "2021-X-230517",
                                    value: "2021-X-230517",
                                    text: "2021-X-230517",
                                  },
                                ]}
                              ></Form.Select>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                      <Grid.Column>
                        <Button disabled>Produkt hinzufügen</Button>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5} verticalAlign="middle">
                        <b>Produktionszeitraum bzw. Lieferzeitraum</b>
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <DateInput
                          name="datesRangeFrom"
                          placeholder="Von"
                          value={format(filterDateFrom, "yyyy-MM-dd")}
                          iconPosition="left"
                          dateFormat="YYYY-MM-DD"
                          onChange={() => {}}
                        />
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <DateInput
                          name="datesRangeTo"
                          placeholder="Von"
                          value={format(filterDateFrom, "yyyy-MM-dd")}
                          iconPosition="left"
                          dateFormat="YYYY-MM-DD"
                          onChange={() => {}}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Header as="h3">Border-Definition</Header>
                <Divider />
                <Grid verticalAlign={"middle"}>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        label={"cradle-to-gate (Produkt bis Auslieferung)"}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        defaultChecked
                        label={"cradle-to-grave (Produktlebenszyklus)"}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={2}>
          <Grid.Row stretched>
            <Grid.Column>
              <Segment>
                <Header as="h3">Meterial-Beschaffung & Vorproduktion</Header>
                <Divider />
                <Grid columns={2} verticalAlign={"middle"}>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        defaultChecked
                        label={"Material-Beschaffung"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled={!created} floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        defaultChecked
                        label={"Material-Vorverarbeitung"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled={!created} floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              <Segment>
                <Header as="h3">Logistik-Inbound & Lagerung</Header>
                <Divider />
                <Grid columns={2} verticalAlign={"middle"}>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        defaultChecked
                        label={"Material-Eingangs-Logistik"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled={!created} floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        defaultChecked
                        label={"Material-Lagerung"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled={!created} floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        defaultChecked
                        label={"Material-Umlagerung-Logistik"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled={!created} floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              <Segment>
                <Header as="h3">Produktion</Header>
                <Divider />
                <Grid columns={2} verticalAlign={"middle"}>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        defaultChecked
                        label={"Eigenproduktion"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled={!created} floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        label={"Handelsware mit Angaben des Hersteller"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        defaultChecked
                        label={"Verpackungsmaterial"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled={!created} floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              <Segment>
                <Header as="h3">
                  Nicht Produktionsrelevante Emissionen aus Betrieb (Aus CCF
                  abgeleitet?)
                </Header>
                <Divider />
                <Grid columns={2} verticalAlign={"middle"}>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        defaultChecked
                        label={"Vertrieb"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled={!created} floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox disabled={false} label={"Marketing"} />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        defaultChecked
                        label={"Verwaltung (IT/HR/Services)"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled={!created} floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Header as="h3">Logistik-Outbound / Distribution </Header>
                <Divider />
                <Grid columns={2} verticalAlign={"middle"}>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        defaultChecked
                        label={"Auslieferung direkt zum Kunden"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled={!created} floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        label={"Auslieferung zum Händler/Marktplatz"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        label={"Auslieferung über Logistik-Partner"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        defaultChecked
                        label={"Umverpackungsmaterial"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled={!created} floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>

              <Segment>
                <Header as="h3">Produktionsverwendung</Header>
                <Divider />
                <Grid columns={2} verticalAlign={"middle"}>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        label={"Schätzung auf Basis Produkteigenschaften"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        label={"Detaillierte Datensammlung"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>

              <Segment>
                <Header as="h3">Entsorgung & Recycling</Header>
                <Divider />
                <Grid columns={2} verticalAlign={"middle"}>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        defaultChecked
                        label={"Schätzung auf Basis Produkteigenschaften"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled={!created} floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        disabled={false}
                        label={"Detaillierte Datensammlung"}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button disabled floated={"right"}>
                        Editieren
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
);
