import React, {FC, useCallback, useState} from "react";
import {
    Grid,
    Header,
    Table,
    Form,
    Button,
    Label,
    Checkbox,
    Divider,
    Dropdown,
    Modal,
    Input,
    Container, Segment
} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";

interface MaintenanceProps extends RouteComponentProps {}

export const Maintenance: FC<MaintenanceProps> = withRouter((props: MaintenanceProps) => {

    const [createCfModalOpen, setCreateCfModalOpen] = useState<boolean>(false);
    const [selectedCfType, setSelectedCfType] = useState<string>("");

    const navigate = useCallback((path) => {
        props.history.push(path);
    }, []);

    const navigateToCFCreate = useCallback(() => {
        console.log(selectedCfType)
        if (selectedCfType == "ccf") {
            navigate("/maintenance/createCCF");
        } else if (selectedCfType == "pcf") {
            navigate("/maintenance/createPCF");
        }
    }, [selectedCfType]);

    return(
        <div>
            <Header as="h1">Konfiguration & Übersicht</Header>
            <Divider/>
            <Grid celled="internally">
                <Grid.Row>
                    <Grid.Column width={5}>
                        <Header as="h2">Stammdaten</Header>
                        <Form>
                            <Form.Field>
                                <label>Name</label>
                                <input disabled value={"Weingut Chasselas"}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Straße</label>
                                <input disabled value={"Chasselas Allee 3"}/>
                            </Form.Field>
                            <Form.Field>
                                <label>PLZ</label>
                                <input disabled value={"1920"}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Ort</label>
                                <input disabled value={"Martigny"}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Land</label>
                                <input disabled value={"CH"}/>
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Header as="h2">Ansprechpartner</Header>
                        <Form>
                            <Form.Field>
                                <label>Name</label>
                                <input value={"Demo Test"} placeholder={"Name"}/>
                            </Form.Field>
                            <Form.Field>
                                <label>E-Mail</label>
                                <input value={"demo.test@wein.chasselas.ch"} placeholder={"E-Mail"}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Telefon</label>
                                <input value={"+0041 321 1340"} placeholder={"Telefon"}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Mobil</label>
                                <input value={""} placeholder={"Mobil"}/>
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <Header as="h2">Org.-Daten</Header>
                        <Grid celled="internally">
                            <Grid.Row>

                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Form>
                                        <Form.Group widhts={16}>
                                            <Form.Field width={16}>
                                                <label>Branche</label>
                                                <input value={"Handel"} placeholder={"Branche 1"}/>
                                            </Form.Field>
                                        </Form.Group>
                                        <Form.Group widths={16}>
                                            <Form.Field width={16}>
                                                    <label>Subbranche</label>
                                                    <input value={"Weinhandel"} placeholder={"Branche 2"}/>
                                            </Form.Field>
                                        </Form.Group>
                                        <Form.Group widths={16}>
                                            <Form.Field width={16}>
                                                <Button floated={"right"} disabled>
                                                    Hinzufügen
                                                </Button>
                                            </Form.Field>
                                        </Form.Group>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Grid columns={"equal"} verticalAlign={"middle"}>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Header as={"label"}>Standorte</Header>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Label>1</Label>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Button floated={"right"} disabled>
                                                    Editieren
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Header as={"label"}>Abteilungen</Header>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Label>1</Label>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Button floated={"right"} disabled>
                                                    Editieren
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Header as={"label"}>Mitarbeiter</Header>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Label>19</Label>
                                            </Grid.Column>
                                            <Grid.Column>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h2">CF Berechnungen</Header>
                        <Table fixed>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    {/*<Table.HeaderCell>Straße/Hausnummer</Table.HeaderCell>*/}
                                    {/*<Table.HeaderCell>PLZ</Table.HeaderCell>*/}
                                    {/*<Table.HeaderCell>Ort</Table.HeaderCell>*/}
                                    {/*<Table.HeaderCell>Land</Table.HeaderCell>*/}
                                    <Table.HeaderCell>Name Ansprechpartner</Table.HeaderCell>
                                    {/*<Table.HeaderCell>Branche 1</Table.HeaderCell>*/}
                                    <Table.HeaderCell>Abteilung</Table.HeaderCell>
                                    <Table.HeaderCell>CF-Typ</Table.HeaderCell>
                                    <Table.HeaderCell>Gültig ab</Table.HeaderCell>
                                    <Table.HeaderCell>Gültig bis</Table.HeaderCell>
                                    <Table.HeaderCell>Aktiviert</Table.HeaderCell>
                                    <Table.HeaderCell>Template</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={"right"}>Transaktionen</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="right">CF Aktionen</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>CCF Jahresberechnung 2022</Table.Cell>
                                    {/*<Table.Cell>Chasselas Allee 3</Table.Cell>*/}
                                    {/*<Table.Cell>1920</Table.Cell>*/}
                                    {/*<Table.Cell>Martigny</Table.Cell>*/}
                                    {/*<Table.Cell>CH</Table.Cell>*/}
                                    <Table.Cell>Hans Müller</Table.Cell>
                                    {/*<Table.Cell>Handel</Table.Cell>*/}
                                    <Table.Cell>-</Table.Cell>
                                    <Table.Cell>CCF</Table.Cell>
                                    <Table.Cell>01.01.2022</Table.Cell>
                                    <Table.Cell>31.12.2022</Table.Cell>
                                    <Table.Cell><Checkbox defaultChecked/></Table.Cell>
                                    <Table.Cell><Checkbox/></Table.Cell>
                                    <Table.Cell textAlign={"right"}>
                                        <Button onClick={navigate.bind(null, "/transaction-add")} icon={"plus"} className={"edit"} />
                                        <Button onClick={navigate.bind(null, "/transaction")} icon={"chain"} className={"edit"} />
                                    </Table.Cell>
                                    <Table.Cell textAlign={"right"}>
                                        {/*<Segment compact>*/}
                                            <Button icon={"copy"} className={"edit"} />
                                            <Button icon={"edit"} className={"edit"} />
                                            <Button icon={"check"} className={"edit"} />
                                            <Button icon={"trash"} className={"delete"} />
                                        {/*</Segment>*/}
                                    </Table.Cell>
                                </Table.Row>
                                {/*<Table.Row>*/}
                                {/*    <Table.Cell>Weingut Chasselas</Table.Cell>*/}
                                {/*    /!*<Table.Cell>Chasselas Allee 3</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell>1920</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell>Martigny</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell>CH</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell>Demo Test</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell>Handel</Table.Cell>*!/*/}
                                {/*    <Table.Cell>Produktion</Table.Cell>*/}
                                {/*    <Table.Cell><Checkbox defaultChecked/></Table.Cell>*/}
                                {/*    /!*<Table.Cell>2021</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell></Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell></Table.Cell>*!/*/}
                                {/*    <Table.Cell><Checkbox/></Table.Cell>*/}
                                {/*</Table.Row>*/}
                                {/*<Table.Row>*/}
                                {/*    <Table.Cell>Weingut Chasselas</Table.Cell>*/}
                                {/*    /!*<Table.Cell>Chasselas Allee 3</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell>1920</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell>Martigny</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell>CH</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell>Demo Test</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell>Handel</Table.Cell>*!/*/}
                                {/*    <Table.Cell>Produktion</Table.Cell>*/}
                                {/*    <Table.Cell><Checkbox defaultChecked/></Table.Cell>*/}
                                {/*    /!*<Table.Cell>2020</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell></Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell></Table.Cell>*!/*/}
                                {/*    <Table.Cell><Checkbox/></Table.Cell>*/}
                                {/*</Table.Row>*/}
                                {/*<Table.Row>*/}
                                {/*    <Table.Cell>Weingut Chasselas</Table.Cell>*/}
                                {/*    /!*<Table.Cell>Chasselas Allee 3</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell>1920</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell>Martigny</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell>CH</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell>Demo Test</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell>Handel</Table.Cell>*!/*/}
                                {/*    <Table.Cell>Produktion</Table.Cell>*/}
                                {/*    <Table.Cell><Checkbox defaultChecked/></Table.Cell>*/}
                                {/*    /!*<Table.Cell>2019</Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell></Table.Cell>*!/*/}
                                {/*    /!*<Table.Cell></Table.Cell>*!/*/}
                                {/*    <Table.Cell><Checkbox/></Table.Cell>*/}
                                {/*</Table.Row>*/}
                            </Table.Body>
                        </Table>
                        <Button floated={"right"} onClick={() => setCreateCfModalOpen(true)} primary>Neuanlage CF</Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as="h2">Klimaschutzprojekte</Header>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Straße/Hausnummer</Table.HeaderCell>
                                    <Table.HeaderCell>PLZ</Table.HeaderCell>
                                    <Table.HeaderCell>Ort</Table.HeaderCell>
                                    <Table.HeaderCell>Land</Table.HeaderCell>
                                    <Table.HeaderCell>Name Ansprechpartner</Table.HeaderCell>
                                    <Table.HeaderCell>Bereich</Table.HeaderCell>
                                    <Table.HeaderCell>Volumen</Table.HeaderCell>
                                    <Table.HeaderCell>CO2 Kompensation</Table.HeaderCell>
                                    <Table.HeaderCell>Jahr</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {/*<Table.Row>*/}
                                {/*    <Table.Cell>Klimawald</Table.Cell>*/}
                                {/*    <Table.Cell></Table.Cell>*/}
                                {/*    <Table.Cell></Table.Cell>*/}
                                {/*    <Table.Cell>Reutlingen</Table.Cell>*/}
                                {/*    <Table.Cell>Deutschland</Table.Cell>*/}
                                {/*    <Table.Cell>Horst Meier</Table.Cell>*/}
                                {/*    <Table.Cell>CO2 Kompensation</Table.Cell>*/}
                                {/*    <Table.Cell>60.000€</Table.Cell>*/}
                                {/*    <Table.Cell>121t</Table.Cell>*/}
                                {/*    <Table.Cell>2022</Table.Cell>*/}
                                {/*</Table.Row>*/}
                                {/*<Table.Row>*/}
                                {/*    <Table.Cell>Klimawald</Table.Cell>*/}
                                {/*    <Table.Cell></Table.Cell>*/}
                                {/*    <Table.Cell></Table.Cell>*/}
                                {/*    <Table.Cell>Reutlingen</Table.Cell>*/}
                                {/*    <Table.Cell>Deutschland</Table.Cell>*/}
                                {/*    <Table.Cell>Horst Meier</Table.Cell>*/}
                                {/*    <Table.Cell>CO2 Kompensation</Table.Cell>*/}
                                {/*    <Table.Cell>110.000€</Table.Cell>*/}
                                {/*    <Table.Cell>250t</Table.Cell>*/}
                                {/*    <Table.Cell>2022</Table.Cell>*/}
                                {/*</Table.Row>*/}
                                {/*<Table.Row>*/}
                                {/*    <Table.Cell>SolarSustain</Table.Cell>*/}
                                {/*    <Table.Cell></Table.Cell>*/}
                                {/*    <Table.Cell></Table.Cell>*/}
                                {/*    <Table.Cell>Neu-Delhi</Table.Cell>*/}
                                {/*    <Table.Cell>indien</Table.Cell>*/}
                                {/*    <Table.Cell></Table.Cell>*/}
                                {/*    <Table.Cell>CO2 Kompensation</Table.Cell>*/}
                                {/*    <Table.Cell>80.000€</Table.Cell>*/}
                                {/*    <Table.Cell>130t</Table.Cell>*/}
                                {/*    <Table.Cell>2021</Table.Cell>*/}
                                {/*</Table.Row>*/}
                            </Table.Body>
                        </Table>
                        <Button floated={"right"} disabled primary>Neuanlage Projektunterstützung</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modal
                onClose={() => setCreateCfModalOpen(false)}
                open={createCfModalOpen}
            >
                <Modal.Header>CF Typ wählen</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={"Company carbon footprint - CCF"}
                                name='cfTypeRadioGroup'
                                value='ccf'
                                checked={selectedCfType === 'ccf'}
                                onChange={(e, data) => setSelectedCfType(data.value as string)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={"Product carbon footprint - PCF"}
                                name='cfTypeRadioGroup'
                                value='pcf'
                                checked={selectedCfType === 'pcf'}
                                onChange={(e, data) => setSelectedCfType(data.value as string)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={"Dienstreise"}
                                name='cfTypeRadioGroup'
                                value='dienstreise'
                                checked={selectedCfType === 'dienstreise'}
                                onChange={(e, data) => setSelectedCfType(data.value as string)}
                                disabled
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                radio
                                label={<Input placeholder={"Neuer CF Typ"}/>}
                                name='cfTypeRadioGroup'
                                value='custom'
                                checked={selectedCfType === 'custom'}
                                onChange={(e, data) => setSelectedCfType(data.value as string)}
                                disabled
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button disabled={selectedCfType == ""} onClick={navigateToCFCreate} primary>Weiter</Button>
                    <Button secondary onClick={() => setCreateCfModalOpen(false)}>Abbrechen</Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
});